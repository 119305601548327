import * as ActionTypes from '../actions/user/type';


export default (state={}, action) => {
  const { type } = action;
  if (type === ActionTypes.LOGIN) {
    return {
      ...state,
      profile: {
        ...action.userData
      },
    };
  } if (type === ActionTypes.LOGOUT) {
    return {};
  } if (type === ActionTypes.UPDATEROOMLIST) {
    return {
      ...state,
      rooms: action.rooms
    };
  } else {
    return state;
  }
}