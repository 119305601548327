import * as ActionTypes from '../actions/create/type';

export default (state={}, action) => {
  const { type } = action;
  if (type === ActionTypes.INITROOMID) {
    return { ...state, roomId: action.roomId };
  } else if (type === ActionTypes.UPDATEROOMSLIDES){
    return {
      ...state,
      slides: { name:action.slides.name, path:action.slides.path }
    }
  } else if (type === ActionTypes.UPDATEROOMCLIP) {
    const { key, clip } = action;
    return {
      ...state,
      clips: [
        ...state.clips,
        key
      ],
      files: {
        ...state.files,
        [key]: {
          ...clip
        }
      }
    }
  } else if (type === ActionTypes.UPDATEROOMCLIPSTATUS) {
    const { key , status } = action;
    return {
      ...state,
      files: {
        ...state.files,
        [key]: {
          ...state.files[key],
          status,
          percentage: status === "Done"? 1: 0
        }
      }
    }
  } else if (type === ActionTypes.UPDATEROOMCLIPPROGRESS) {
    const { key, percent } = action;
    return {
      ...state,
      files: {
        ...state.files,
        [key]: {
          ...state.files[key],
          percentage: percent
        }
      }
    }
  } else if (type === ActionTypes.UPDATEROOMATTACHMENT) {
    const { key, attachment } = action;
    return {
      ...state,
      attachments: [
        ...state.attachments,
        key
      ],
      files: {
        ...state.files,
        [key]: {
          ...attachment
        }
      }
    }
  } else if (type === ActionTypes.UPDATEROOMATTACHMENTSTATUS) {
    const { key , status } = action;
    return {
      ...state,
      files: {
        ...state.files,
        [key]: {
          ...state.files[key],
          status,
          percentage: status === 'Done'? 1: 0
        }
      }
    }
  } else if (type === ActionTypes.UPDATEROOMATTACHMENTPROGRESS) {
    const { key, percent } = action;
    return {
      ...state,
      files: {
        ...state.files,
        [key]: {
          ...state.files[key],
          percentage: percent
        }
      }
    }
  } else if (type === ActionTypes.UPDATEROOMINFO) {
    return {
      ...state,
      title:action.title,
      brief:action.brief,
    }
  } else if (type === ActionTypes.PENDINGQUEUEPUSH) {
    return {
      ...state,
      queue: state.queue+1
    }
  } else if (type === ActionTypes.PENDONGQUEUESHIFT) {
    return {
      ...state,
      queue: state.queue-1
    }
  } else if (type === ActionTypes.CANCELFILE){
    const { newData } = action
    return {
      ...state,
      files:newData
    }
  }else {
    return state;
  }
}
