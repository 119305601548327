import React from 'react'
import styled from 'styled-components'
//config
import config from '../config'
//components
import Button from '../components/Button'
import Clip from '../components/Clip'
import Title from '../components/Title'
import Subtitle from '../components/Subtitle'
import Content from '../components/Content'
import Chapter from '../components/Chapter'
import SubscribeModal from '../components/SubscribeModal'
//router
import { Link, Redirect } from 'react-router-dom'
// axios
import { get, post } from 'axios';
import qs from 'qs';
// connect
import { connect } from 'react-redux';
// action
import { setCourse, toggleSubscribe, toggleUserDropdown } from '../redux/Actions';

import Modal from '@material-ui/core/Modal';
import axios from 'axios';
// cookie
const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");

const Wrapper = styled.div`
text-align:center;
  width:80%;
  height:100%;
  margin:0 auto;
  grid-area:${props => props.areaname};
  display:grid;
  grid-template-columns:repeat(4, 25%);
  grid-template-rows: 50px 1fr 50px 50px 30px auto auto 50px 50px;
  grid-template-areas:
  '. . . .'
  'clip clip clip clip'
  '. . . .'
  'title title title chapters'
  'subtitle subtitle . chapters'
  'brief brief brief chapters'
  '. . . .'
  'aaa preBtn nextBtn .'
  '. . . .';
  grid-gap:20px;
  @media screen and (max-width:1023px){
    grid-template-columns:repeat(4, 22%);
    width:90%;
    grid-gap:10px 4%;
    grid-template-rows: 50px 1fr 30px 50px 30px auto 50px auto 50px 50px 50px;
    grid-template-areas:
    '. . . .'
    'clip clip clip clip'
    '. . . .'
    'title title title title'
    'subtitle subtitle . .'
    'brief brief brief brief'
    '. . . .'
    'chapters chapters chapters chapters'
    '. . . .'
    'preBtn preBtn nextBtn nextBtn'
    '. . . .';
  }
`;

const ClipContainer = styled.div`
  grid-area: clip;
  padding-top: calc(100%*9/16);
  position:relative;
`;

const ChapterContainer = styled.div`
  grid-area: chapters;
`;

const mapStateToProps = state => ({
  ...state.courses,
  loginModalIsOpen: state.modals.loginModalIsOpen,
});

const mapDispatchToProps = dispatch => ({
  changeCourseData(data) {
    dispatch(setCourse(data));
  },
  toggleModal(state) {
    dispatch(toggleSubscribe(state));
  },
  openUserDropdown(state) {
    dispatch(toggleUserDropdown(state));
  },
});

class CourseIntro extends React.Component {
  state = {
    redirect: false,
    collection: false,
    userId: '',
    subscribe: false,
    loading: false,
    user: {}
  };

  redirect = () => {
    this.setState({ redirect: true });
  };

  componentDidMount = async () => {
    document.title = "課程详情"
    // setTimeout(()=>{
    //   this.props.openUserDropdown(false)
    // },500)
    var _this = this
    window.scrollTo(0, 0);
    const { match, changeCourseData } = this.props;
    const url = `${config.env.edu}course/${match.params.courseId}`;
    let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
    if (!!!arr) {
      this.props.history.push('/login')
    }
    else {
      axios.defaults.withCredentials = true;
      axios.get(`${config.env.server}getcookieuserid`).then(async res => {
        if (res.data[0][0]) {
          // if (res.data[0][0].identity === 1 && res.data[0][0].identity === 0) {
          //   _this.props.history.push('/login')
          //   return;
          // }

          // axios.post(`${config.env.edu}profile/usercourseJuri`, qs.stringify({ courseid: `${this.props.match.params.courseId}`, userid: res.data[0][0].userid })).then(async res => {
          //   if (res.data[0][0].jurisdiction == 1) {
          //   }
          //   else {
          //     _this.props.history.push('/login');
          //   }
          // }).catch(err => {
          //   _this.props.history.push('/login');
          // })
          this.setState({
            user: res.data[0][0]
          })
          await get(url).then(res => (res.data[0][0].chapters = JSON.parse(res.data[0][0].chapters),
            changeCourseData(res.data[0][0]))).catch(err => (_this.redirect()));
          _this.setState({
            userId: res.data[0][0].userid
          });
          _this.getColl(res.data[0][0].userid);
        }
        else {
          _this.props.history.push('/login');
        }
      }).catch(err => {
        _this.props.history.push('/login');
        //window.alert(err);
      })
    }
    await this.setState({
      loading: false
    })
  }

  // 判断是否收藏
  getColl = (userId) => {
    get(`${config.env.edu}course/${userId}/${this.props.match.params.courseId}`).then(res => {
      if (res.data[0][0]) {
        this.setState({
          collection: res.data[0][0].iscollection === 1 ? true : false,
          subscribe: res.data[0][0].issubscribe === 1 ? true : false,
        })
      }
    })
  }

  // 点击收藏
  collection = () => {
    let mode = `mode=,${this.state.userId},${this.props.match.params.courseId}`
    post(`${config.env.edu}collection/create`, mode).then(res => {
      if (res.data) {
        this.setState({
          collection: !this.state.collection
        })
      }
    }).catch(err => {
      if (err.response.data) {
        alert(err.response.data)
      }
    })
  }

  // 点击訂閱
  subscribe = () => {
    let mode = `mode=,${this.state.userId},${this.props.match.params.courseId}`
    post(`${config.env.edu}subscribe/create`, mode).then(res => {
      if (res.data) {
        this.setState({
          subscribe: !this.state.subscribe
        })
      }
    }).catch(err => {
      if (err.response.data) {
        alert(err.response.data)
      }
    })
  }

  // sendGAEvent = () => {
  //   console.log(window.gtag)
  //   window.gtag('event', '點擊', {
  //     'event_category': '訂閱按鈕',
  //     'event_label': '課程'
  //   });
  // }

  componentWillUnmount = () => {
    this.props.openUserDropdown(true)
  }

  render() {
    const { loginModalIsOpen, state, language } = this.props
    const { subscribe } = this.state
    console.log(this.props)
    return (
      <Wrapper areaname={this.props.areaname}>
        <ClipContainer>
          <Clip
            areaname=''
            source={this.props.demo}
            poster={this.props.poster}
            backgroundColor='#45699A'
            css={`
              padding: 10px;
              box-sizing: border-box;
              position: absolute;
              top: 0;
              left: 0;
            `}
          />
        </ClipContainer>
        <Title
          language={language}
          state={state}
          areaname='title'
          css={`
            text-align:left;
          `}
        />
        <Subtitle
          areaname='subtitle'
          name={`By ${this.props.author ? this.props.author : 'CocoRobo'}`}
          css={`
            text-align:left;
          `}
        />
        <Content
          areaname='brief'
          object={this.props.chapters}
          text={this.props.brief}
          css={`
            text-align:left;
            box-sizing:border-box;
            padding-right:5%;
          `}
        />
        <ChapterContainer>
          <Chapter
            areaname=''
            chapters={this.props.chapters}
          />
        </ChapterContainer>
        {loginModalIsOpen ? <Button
          onClick={this.collection}
          name={this.state.collection ? `${language.course_already_collection}` : `${language.course_collection}`}
          primary
          css={`
                grid-area: aaa;
                background:${this.state.collection ? '#fff' : 'rgb(253,171,29)'};
                box-shadow:3px 3px 0 rgb(253,171,29);
                width: 50%;
                color:${this.state.collection ? 'rgb(253,171,29)' : '#fff'};
                border:2px solid rgb(253,171,29);
                @media screen and (max-width:1023px){
                  min-width: 100%;
                }
                `} /> : ""}

        <Link to={'/'}
          css={`
            grid-area: preBtn;
            text-decoration: none;
            display: flex;
            justify-content: flex-end;
          `}>
          <Button
            name={language.return_home}
            secondary
            css={`
              min-width: 50%;
              margin-rigt:0;
              @media screen and (max-width:1023px){
                min-width: 100%;
              }`} />
        </Link>
        {state !== 0 && (this.state.user.identity == 0 || this.state.user.identity == 2) ? <Link to={`${this.props.match.url}/chapter`}
          css={`
              grid-area: nextBtn;
              text-decoration: none;
            `}>
          <Button
            onClick={() => {
              if (this.props.state == 1) {
                this.props.history.push(`${this.props.match.url}/chapter`)
              } else {
                console.log(`${config.env.basename}/tutor/recorded/${this.props.match.params.courseId}`)
                window.location.href = `${config.env.basename}/tutor/recorded/${this.props.match.params.courseId}`
              }
            }}
            name={this.props.state == 1 ? language.info_course : language.info_record}
            primary
            css={`
                min-width: 50%;
                grid-area: nextBtn;
                margin:0 auto;
                @media screen and (max-width:1023px){
                  min-width: 100%;
                }
                `} />
        </Link> :
          <Button
            name={subscribe ? `${language.course_already_subscribe}` : `${language.course_subscribe}`}
            primary
            onClick={this.subscribe}
            css={`
                grid-area: nextBtn;
                min-width: 50%;
                margin:0 auto;
                @media screen and (max-width:1023px){
                  min-width: 100%;
                }
                `} />}
        <SubscribeModal match={this.props.match} />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.loading}
          onClose={() => {
            this.setState({
              loading: true
            })
          }}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <img src={require('../assets/img/loading1.gif')} style={{ width: 500, height: 300, position: 'absolute', top: 'calc(50% - 150px)', left: 'calc(50% - 250px)' }} />
            <p style={{ position: 'absolute', width: '100%', top: 'calc(50% + 75px)', textAlign: 'center', color: '#fff' }}>{language.loadings}</p>
          </div>
        </Modal>
      </Wrapper>
    )
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(CourseIntro);
