import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
//components
import Subtitle from './Subtitle'

const Wrapper = styled.div`
  grid-area:${props => props.areaname};
  display:flex;
  margin:20px 0 20px 0;
  //margin-bottom: 1.2rem;
`;
const Icon = styled.img`
  box-sizing:border-box;
  padding:10px;
  align-self:center;
  @media screen and (max-width:1023px){
    width:50px;
    height:50px;
  }
`;
const Content = styled.div`
  align-self:center;
`;


class Eraser extends React.Component {
  render() {
    return (
      <Wrapper areaname={this.props.areaname}>
        <Icon
          src={this.props.img}
        />
        <Content>
          <Subtitle
            name={this.props.name}
            color='#27303B'
            css={`
              font-size:1.5rem;
              @media screen and (max-width:1023px){
                font-size:16px;
              }
              `}
          />
        </Content>
      </Wrapper>
    )
  }
}

export default Eraser;
