import React from 'react'
import styled from 'styled-components'
import MobileDetect from 'mobile-detect'

//image
import {ReactComponent as Rotate} from '../assets/img/SuggestResolution__Rotate.svg'

const Wrapper = styled.div`
  display:none;
 @media screen and (max-width:1023px){
   display:grid;
   width:auto;
   height:100vh;
 }
`;
const Content= styled.p`
  place-self:center;
  margin:0;
  padding:0;
  width:auto;
  line-height:48px;
  font-size:24px;
  @media screen and (max-width:767px){
    width:80%;
  }
`;

class SuggestResolution extends React.Component {
  state = {
    tablet:false
  }
  componentDidMount(){
    this.detectDevice();
  }
  detectDevice(){
    var md = new MobileDetect(window.navigator.userAgent);
    if(md.tablet()){
      this.setState({
        tablet:true
      })
    }
  }
  render () {
    return(
      <Wrapper>
        {this.state.tablet &&
          <div css={`
              place-self:center;
              display:grid;
              `}>
              <Rotate
                width='100px'
                height='100px'
                css={`
                  place-self:center;
                  `}
                />
              <Content>旋轉螢幕以獲得更好的用戶體驗</Content>
            </div>
        }
        {!this.state.tablet &&
          <Content>
              移動版本優化中，請使用 PC 進行瀏覽。
              <br/>
              建議分辨率大於 1024*768 px
           </Content>
        }

      </Wrapper>
    )
  }
}

export default SuggestResolution;
