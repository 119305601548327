import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import _ from '../utils'
import { TextField } from '@material-ui/core'
import { initChapters, setStep, updateChapters, updateCourseDemo, updateCourseInfo, updateCourseCover } from '../actions/newupload'

import { Button } from '@material-ui/core'
import AddButton from './AddButton'
import DelButton from './DelButton'
import Subtitle from './Subtitle'

//router
import { Redirect, Link } from 'react-router-dom'
import config from '../config'
import axios from 'axios'
import { type } from 'os'
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const windowHeight = window.innerHeight;
const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
const Wrapper = styled.div`
	margin-top: 50px;
	margin-bottom: 25px;
	width: 60%;
	display: grid;
	justify-self: center;
	align-content: start;
	grid-template-columns: 10% 30% 20% 30% 10%;
	grid-template-rows: 50px 293px 95px 82px;
	grid-template-areas:
	'title title title title title'
	'container container container container container'
	'. . add . .'
	'. last upload next .';
	position:relative;
`;

const Container = styled.div`
	overflow: auto;
	grid-area: container;
`;

const InputWrapper = styled.div`
	display: grid;
	height: 70px;
	grid-template-columns: 15% 75% 10%;
  	grid-template-areas:
  	'index content cancel';
`;

const Index = styled.div`
  	display: grid;
  	grid-area: index;
  	align-content: center;
`;

const Delete = styled.div`
	grid-area: cancel;
  	justify-self: start;
	align-self: end;
	margin-left: 12px;
`;



const mapStateToProps = (state) => {
	return {
		activeStep: state.steppers.activeStep,
		prechaptertitles: state.newupload.chapter.prechaptertitles,
		prekeys: state.newupload.chapter.prekeys,
		chapters: state.newupload.chapter.chapters,
		activeStep: state.steppers.activeStep,
		title: state.newupload.course.title,
		brief: state.newupload.course.brief,
		cover: state.newupload.course.cover,
		demo: state.newupload.course.demo,
		number: state.newupload.course.number
	}
}
const mapDispatchToProps = dispatch => {
	return {
		initChapters(initchapters, prechaptertitles, prekeys, length) {
			dispatch(initChapters(initchapters, prechaptertitles, prekeys, length));
		},
		handleSet(activeStep) {
			dispatch(setStep(activeStep))
		},
		updateChapters(chapters) {
			dispatch(updateChapters(chapters))
		},
		updateCourseInfo(title, brief) {
			dispatch(updateCourseInfo(title, brief))
		},
		updateCourseCover(cover) {
			dispatch(updateCourseCover(cover))
		},
		updateCourseDemo(demo) {
			dispatch(updateCourseDemo(demo))
		},
	}
}

class ChapterInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			chapter: (props.chapters.length) ? props.chapters.map((x, i) => { let obj = { id: i, title: x.title }; return obj }) : props.prechaptertitles,
			count: 0,
			userId: '',
			switchShow: false
		}
		props.handleSet(1);
	}
	bodyHeight = document.body.offsetHeight

	keys = (this.props.number && this.props.chapters.length) ? this.props.chapters.map((x, i) => i) : this.props.prekeys

	componentDidMount() {
		let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
		if (arr) {
			axios.get(`${config.env.server}getcookieuserid`).then(res => {
				if (res.data) {
					this.setState({
						userId: res.data[0][0].userid
					});
				}
			}).catch(err => {
				this.props.history.push('/login');
				// window.alert(err.response.data)
			})
		}
		let data = JSON.parse(window.localStorage.obj)
		if (!data.title && !data.brief) {
			this.props.history.push('/upload')
		}
	}

	add = e => {
		let arr = this.state.chapter
		let obj = { title: '', id: this.keys[this.keys.length - 1] + 1 }
		arr.push(obj)
		this.keys.push(this.keys[this.keys.length - 1] + 1)
		this.setState({
			chapter: arr
		});
	}

	remove = key => e => {
		const { state } = this;
		const { chapters } = this.props
		this.keys = this.keys.filter(v => v !== key);
		let arrs = state.chapter.filter((x, i) => x.id !== key)
		if (chapters.length !== 0) {
			let findArr = state.chapter.find((x, i) => x.id === key)
			let arr = []
			arr = chapters.filter(x => x.title !== findArr.title)
			this.props.updateChapters(arr)
		}
		this.setState({ chapter: arrs });
	}

	getTitleVal = key => {
		return data => {
			this.setState({
				chapter: {
					...this.state.chapter,
					[key]: data
				}
			});
		}
	}

	goLastChapter = () => {
		this.props.history.push('/upload')
	}

	submitChapters = () => {
		this.getData('111')
	}

	getData = type => {
		const { keys, state } = this;
		const { updateChapters, chapters } = this.props
		let prechaptertitles = state.chapter;
		let arr = [];
		if (chapters.length !== 0) {
			state.chapter.map((t, i) => {
				// if (!t.title) {
				// 	alert(`第${i + 1}章节标题为空`)
				// 	return;
				// }
				arr.push(Object.assign({}, {
					id: (i + 1),
					title: t.title,// 章节标题
					checked: chapters[i] ? chapters[i].checked : false,
					teacher: chapters[i] ? chapters[i].teacher : {
						bg: [],//缩略图
						intro: '',//描述
						clipUrl: [],//视频
						slideUrl: [],//PPT
						attachmentUrl: [],//附件
					},
					student: chapters[i] ? chapters[i].student : {
						bg: [],//缩略图
						intro: '',//描述
						clipUrl: [],//视频
						slideUrl: [],//PPT
						attachmentUrl: [],//附件
					},
				}))
			})
		} else {
			state.chapter.map((t, i) => {
				// if (!t.title) {
				// 	alert(`第${i + 1}章节标题为空`)
				// 	return;
				// }
				arr.push(Object.assign({}, {
					id: (i + 1),
					title: t.title,// 章节标题
					checked: false,
					teacher: {
						bg: [],//缩略图
						intro: '',//描述
						clipUrl: [],//视频
						slideUrl: [],//PPT
						attachmentUrl: [],//附件
					},
					student: {
						bg: [],//缩略图
						intro: '',//描述
						clipUrl: [],//视频
						slideUrl: [],//PPT
						attachmentUrl: [],//附件
					}
				}))
			})
		}
		let count = 0;
		let reg = '^[^(\u4E00-\u9FA5A-Za-z0-9)]+$';
		arr.map(x => {
			if (x.title && !x.title.match(reg)) {
				count++;
			}
			if (x.checked === undefined) {
				x.checked = true
			}
			return x
		})
		if (arr.length !== count) {
			alert(this.props.language.chapter_no_perfect);
			return;
		}
		window.localStorage['arr'] = JSON.stringify(arr)
		window.localStorage['keys'] = JSON.stringify(keys)
		window.localStorage['prechaptertitles'] = JSON.stringify(prechaptertitles)
		updateChapters(arr)
		this.props.initChapters(arr, prechaptertitles, keys);
		if (type) {
			this.props.history.push('/upload/chapter/edit')
		}
	}

	titleDebounced = _.debounce(this.props.getTitleVal || function () { }, 500);
	updateTitleVal = (e, key) => {
		const { state } = this;
		e.preventDefault();
		const value = e.target.value;
		let arr = state.chapter.map((x, i) => {
			const obj = x;
			if (x.id === key) {
				obj.title = value
				obj.show = false
			}
			return obj;
		})
		this.setState({
			chapter: arr
		});
		// this.titleDebounced(value);
	};

	// 訂閱上傳
	upload = async () => {
		const { initChapters, updateCourseCover, updateCourseDemo, updateCourseInfo } = await this.props;
		// await this.getData();
		const params = await this.uploadData();
		if (params) {
			if (window.localStorage.number && JSON.parse(window.localStorage.number)) {
				axios.post(`${config.env.edu}updateCourses`,
					`mode=${this.state.userId},${JSON.parse(window.localStorage.number)},${params.encodetitle},${params.encodebrief},${params.encodedemo},${params.encodecover},${params.encodechapters},0`
				).then((res => {
					if (res.status === 200) {
						// 修改成功清空数据
						updateCourseInfo(null, null);
						updateCourseCover([]);
						updateCourseDemo([]);
						initChapters([], [{ title: '' }], [0]);
						localStorage.removeItem('number')
						localStorage.removeItem('obj')
						localStorage.removeItem('arr')
						localStorage.removeItem('keys')
						localStorage.removeItem('prechaptertitles')
						window.location.href = '/upload/result/' + res.data[0][0].number
					}
				})).catch((err) => {
					window.alert(this.props.language.change_failure + ',error:' + err)
				})
			} else {
				var uploadid = window.localStorage["uploadid"] || window.Guid.newGuid();
				axios.post(`${config.env.edu}createCourses`,
					`mode=${this.state.userId},${params.encodetitle},${params.encodebrief},${params.encodedemo},${params.encodecover},${params.encodechapters},0,${uploadid}`
				).then((res => {
					if (res.status === 200) {
						// 上傳成功清空数据
						updateCourseInfo(null, null);
						updateCourseCover([]);
						updateCourseDemo([]);
						initChapters([], [{ title: '' }], [0]);
						localStorage.removeItem('number')
						localStorage.removeItem('obj')
						localStorage.removeItem('arr')
						localStorage.removeItem('keys')
						localStorage.removeItem('prechaptertitles')
						window.location.href = '/upload/result/' + res.data[0][0].number
					}
				})).catch((err) => {
					window.alert(this.props.language.upload_fail + err)
				})
			}
		}
	}

	guid = () => {
		var _num, i, _guid = "";
		for (i = 0; i < 32; i++) {
			_guid += Math.floor(Math.random() * 16).toString(16); //随机0  - 16 的数字 转变为16進制的字符串
			_num = Math.floor((i - 7) / 4);                        //计算 (i-7)除4
			if (_num > -1 && _num < 4 && (i == (7 + 4 * _num))) {    //会使guid中間加 "-"   形式为xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
				_guid += "-";
			}
		}
		return _guid;
	}

	uploadData = () => {
		const { state } = this
		const { title, brief, cover, demo, chapters, language } = this.props
		let Chapters = [];
		let isNull = false
		if (title === '' || brief === '' || cover.length === 0 || demo.length === 0) {
			isNull = true;
			window.alert(language.course_information_no_perfect);
			return false;
		}
		if (isNull === false) {
			for (let i = 0; i < state.chapter.length; i++) {
				Chapters.push({
					chapterid: this.guid(),
					title: state.chapter[i].title,
					teacher: {
						bg: '',
						intro: '',
						clipUrl: [],
						slideUrl: [],
						attachmentUrl: []
					},
					student: {
						bg: '',
						intro: '',
						clipUrl: [],
						slideUrl: [],
						attachmentUrl: []
					}
				})
			}
		}
		let param = {
			encodetitle: encodeURIComponent(encodeURIComponent(title)),
			encodebrief: encodeURIComponent(encodeURIComponent(brief)),
			encodecover: encodeURIComponent(encodeURIComponent(cover[0].url)),
			encodedemo: encodeURIComponent(encodeURIComponent(demo[0].url)),
			encodechapters: encodeURIComponent(encodeURIComponent(JSON.stringify(Chapters))),
		}
		return param;
	}

	// 点击切换是否是訂閱課程
	switchCheck = e => {
		this.setState({
			switchShow: !this.state.switchShow
		})
	}

	render() {
		const { language } = this.props
		const { switchShow } = this.state
		return (
			<Wrapper>
				<div css={`
					grid-area: title;
					text-align: start;
				`}>
					<Subtitle
						name={`${language.upload_course_content}:${language.chapter_directory_write}`}
						inline={true}
					/>
					{window.localStorage.identity && (JSON.parse(window.localStorage.identity) === 0 || JSON.parse(window.localStorage.identity) === 2) ? <div css={`
					    display: inline-block;
						position: absolute;
						right: 0px;
						top: 0px;`}>
						<FormControlLabel
							value={switchShow}
							control={<Switch color="primary" onClick={this.switchCheck} />}
							label={language.click_is_subscribe}
							labelPlacement="end"
						/>
					</div> : ""}
				</div>
				<Container>
					<span style={{ fontSize: 16, color: '#ccc', marginLeft: 180 }}>{`${JSON.parse(window.localStorage.language) === 1 ? language.chapter : '共'}${this.keys.length}${JSON.parse(window.localStorage.language) === 1 ? '' : '章'}`}</span>
					{this.keys.map((v, i) => (
						<InputWrapper key={v}>
							<Index>
								<Subtitle name={`${language.chapter}${i + 1}${JSON.parse(window.localStorage.language) === 1 ? '' : '章'}`} />
							</Index>
							<div css={`margin-left: 15px;
								float: left;
								width: 90%;
								justify-self: center;`
							}>
								<TextField
									id={v}
									fullWidth={true}
									onChange={(e) => this.updateTitleVal(e, v)}
									value={this.state.chapter[i].title}
									title={language.write_chapter_title}
									areaname="content"
									// getTitleVal={this.getTitleVal(v)}
									variant='outlined'
									margin="dense"
									placeholder={language.chapter_title}
								/>

							</div>
							{this.keys.length > 1 ?
								(<Delete>
									<DelButton
										width='40px'
										fn={this.remove(v)}
									/>
								</Delete>) : ''
							}
						</InputWrapper>
					))}
				</Container>
				<AddButton
					area="add"
					height={40}
					click={this.add}
					place="center"
					language={language} />
				{switchShow ? <div css={`
					    text-decoration: none;
						grid-area:upload
						width:80%;
						place-self: center;`}>
					<Button
						variant="contained"
						color="primary"
						size="large"
						onClick={this.upload}
					>
						{language.chapter_complete}
					</Button>
				</div> : <div
					css={`
						text-decoration: none;
						grid-area:last
						width:70%;
						place-self: center;
					`}>
						<Button
							variant="contained"
							color="primary"
							size="large"
							onClick={this.goLastChapter}>
							{language.prev}
						</Button>
					</div>}
				{!switchShow && <div
					css={`
						text-decoration: none;
						grid-area:next
						width:70%;
						place-self: center;
					`}>
					<Button
						variant="contained"
						color="primary"
						size="large"
						onClick={this.submitChapters}>
						{language.next}
					</Button>
				</div>}
			</Wrapper>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterInfo);
