import React from 'react'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import { connect } from 'react-redux';
// Actions
import {
    updateUploader
} from '../actions/newupload'
const Wrapper = styled.div`
  grid-area: uploader;
`;
const Text = styled.div`
    font-weight: bold;
    text-align: left;
    float: left;
    font-size: 14px;
    margin-top: 25px;
`
const Divider = styled.hr`
    align-self: center;
    grid-area:divider;
    height: 1px;
    border: none;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.3);
    margin: 0;
`;

const mapStateToProps = (state) => {
    return {
        ...state.newupload.uploader,
        organize: state.newupload.uploader.organize,
        uploader: state.newupload.uploader.uploader
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUploader(organize, uploader) {
            dispatch(updateUploader(organize, uploader))
        },
    }
}
class Uploader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            organize: '',
            uploader: '',
        }
    }
    componentWillReceiveProps(nextProps){
        if (this.props.organize !== nextProps.organize){
            this.setState({
                organize:nextProps.organize
            })
        }
        if(this.props.organize !== nextProps.organize){
            this.setState({
                uploader:nextProps.uploader
            })
        }
    }
    componentDidMount() {
        let {organize,uploader}=this.props
        this.setState({
            organize:organize,
            uploader:uploader

        })
    }
    componentWillUnmount(){
        let {organize,uploader}=this.state
        this.props.updateUploader(organize,uploader)
    }
    updateOrganize=(e)=>{
        this.setState({
            organize:e.target.value
        })
        let {organize,uploader}=this.state
        updateUploader(organize, uploader)
    }
    updateUploader=(e)=>{
        this.setState({
            uploader:e.target.value
        })
        let {organize,uploader}=this.state
        updateUploader(organize, uploader)
    }
    render() {
        return (
            <Wrapper>
                <div css={`width:48%;float:left;`}>
                    <Text>上傳者學校/機構名稱:</Text>
                    <div css={`width:100%;`}>
                    <TextField
                        onChange={this.updateOrganize}
                        value={this.state.organize}
                        label={'此處填寫學校/機構名稱'}
                    ></TextField></div>
                </div>
                <div css={`width:48%;float:left;`} >
                    <Text>上傳者名稱:</Text>
                    <div css={`width:100%;`}>
                    <TextField
                        onChange={this.updateUploader}
                        value={this.state.uploader}
                        label={'此處填寫上傳者名稱'}
                    ></TextField></div>
                </div>
                <div css={`clear:both;margin:80px 280px 0 280px;`}><Divider /></div>
            </Wrapper>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Uploader);