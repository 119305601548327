import React from 'react'
import styled from 'styled-components'

//components
import DragDropBrowse from './DragDropBrowse'
import ProcessList from './ProcessList'

// svg
import { ReactComponent as VideoIcon } from '../assets/img/Upload__Icon--video.svg'


const Wrapper = styled.div`
  display:grid;
  grid-template-columns:45% 55%;
  grid-area:${props=>props.areaname};
`;

const PlaceholderIcon = styled.img`
  width:30%;
`;

class MultipleUpload extends React.Component {
  render () {
    const { areaname, uploadFile, icon, keys, files } = this.props;
    return (
      <Wrapper areaname={areaname}>
        <DragDropBrowse
          inputid={this.props.inputid}
          type={this.props.type}
          upload={uploadFile}
          max-size={this.props['max-size']}
          max-length={this.props['max-length']}
        >
          <div>
            <PlaceholderIcon src={icon} width='100%' height='100%'/>
            <div>
              {this.props.placeholderContent}
            </div>
          </div>
        </DragDropBrowse>
        <ProcessList
          icon={icon}
          files={files}
          keys={keys}
          cancelFile={this.props.cancelFile}
        />
      </Wrapper>
    )
  }
}

export default MultipleUpload;
