import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';

const Wrapper = styled.div`
  width:100%;
  height:100%;
  grid-area:${props => props.areaname};
  color:${props => props.color || '#232A34'};
  // overflow: hidden;
  // text-overflow:ellipsis;
  // white-space: nowrap;
`;
const Spans = styled.h1`
max-width:calc(100% - 120px);
overflow: hidden;
font-size:3em;
margin:0;
padding:0;
text-overflow:ellipsis;
white-space: nowrap;
`;

class Title extends React.Component {
  render() {
    const { state,language } = this.props
    return (
      <Wrapper
        areaname={this.props.areaname}
        color={this.props.color}
        {...this.props}
      >
        <Spans>{this.props.titleContent}</Spans>
        {state === 0 ? <span style={{position:'relative',top:17,left:10, background: 'linear-gradient(90deg,#00aeff,#3369e7)', color: '#fff', borderRadius: 20, padding: '10px 15px', fontSize: 14, boxShadow: '0 2px 6px 0 rgba(51,105,231,.4)',marginLeft:10 }}>{language.forthcoming}</span> : ''}
      </Wrapper>
    )
  }
}

function mapStateToProps(state) {
  return {
    titleContent: state.courses.title
  };
}

export default connect(mapStateToProps)(Title);
