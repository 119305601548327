import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Route ,Switch } from 'react-router-dom'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
//components
import CourseInfo from '../components/CourseInfoUpload'
import ChapterInfo from '../components/ChapterInfoUpload'
import Chapter from '../components/ChapterUpload'
import Steppers from '../components/Steppers'
import RoomCreated from '../components/RoomCreated'
import RoomInfo from '../components/RoomInfo'
import RoomResult from '../components/RoomResult'

const Wrapper = styled.div`
  display: grid;
  grid-area: ${props=>props.areaname};
  grid-template-columns: 20% 80%;
  grid-template-areas:
  'steppers uploadArea';
`;

const UploadArea = styled.div`
  display: grid;
  grid-area: uploadArea;
`;

const SteppersArea = styled.div`
  border-right:1px solid #3c5e8f;
`;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3D67BC'
    }
  },
  typography: {
    useNextVariants: true
  },
});


class RoomCreate extends React.Component {
  render () {
    return(
      <Wrapper areaname={this.props.areaname}>
        <MuiThemeProvider theme={theme}>
          <SteppersArea>
            <Steppers
              steps={['配置房間', '創建成功並分享']}
              stepsContent={['此步驟將完成房間相關信息的配置','成功']}
            />
          </SteppersArea>
          <UploadArea>
            <Switch>
              <Route path='/create/success' component = {RoomResult} />
              <Route render={({match})=>(<RoomInfo match={match}/>)} />
              <Route render={({match})=>(<RoomCreated match={match}/>)} />
            </Switch>
          </UploadArea>
        </MuiThemeProvider>
      </Wrapper>
    )
  }
}

export default RoomCreate;
