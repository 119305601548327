import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import '../css/Submission.css';
import axios from 'axios';
import qs from 'qs';
import config from '../config'
import { toggleRoom, toggleLogin, toggleUserDropdown } from '../redux/Actions';

const regEmail = new RegExp("[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+$");
const regpass = new RegExp(/^(?=.*\d)(?=.*[A-Za-z]).{6,}$/);
const mapDispatchToProps = (dispatch) => ({
    openRoomModal(state) {
        dispatch(toggleRoom(state));
    },
    toggleLogin(state) {
        dispatch(toggleLogin(state));
    },
    openUserDropdown(state) {
        dispatch(toggleUserDropdown(state));
    },
});
const mapStateToProps = state => {
    const { profile } = state.user;
    return {
        loginModalIsOpen: state.modals.loginModalIsOpen,
        userDropdownIsOpen: state.modals.userDropdownIsOpen,
        account: profile && profile.account,
        name: profile && profile.name
    };
};

class MobileHome extends React.Component {
    // const [completed, setCompleted] = useState(0)
    constructor(props) {
        super(props)
        this.state = {
            isShow: true,
            email: '',   // 郵箱
            passWord: '',    // 密碼
            clickShow: true, // 點擊後自毀
            uploadVideo: false,
        }
    }

    componentDidMount() {
        document.getElementsByClassName('mobile_home')[0].parentNode.children[0].style.display = 'none'
        document.getElementsByClassName('mobile_home')[0].parentNode.children[2].style.display = 'none'
        if (this.QueryString('email') && this.QueryString('key')) {
            this.setState({
                isShow: false
            })
        }
    }

    // 截取某個字符串
    QueryString = (item) => {
        var sValue = window.location.search.match(new RegExp("[\?\&]" + item + "=([^\&]*)(\&?)", "i"));
        return sValue ? sValue[1] : sValue;
    }

    submit = () => {

    }

    render() {
        const { uploadVideo } = this.state
        return (
            <div className="mobile_home" >
                {/* <!-- 頭部 --> */}
                <div className="yl-head">
                    <Link to="/mobileHome">
                        <span><img src={require('../assets/images/return.png')} alt="" /></span>
                    </Link>
                    <span>cocorobo作品提交</span>
                </div>
                {/* <!-- 内容 --> */}
                <div className="tj-name">
                    <span className="tj-span">作品名称</span>
                    <input type="text" name="" id="" placeholder="输入作品名称" className="tj-input" />
                </div>
                <div className="tj-ship">
                    <span>上傳視頻</span>
                    <div>
                        {uploadVideo ? <img src={require('../assets/images/bj.png')} alt="" /> : <span>點擊上傳</span>}
                        <input type="file" />
                    </div>
                </div>
                <div className="tj-ship">
                    <span>添加圖片</span>
                    <div>
                        {uploadVideo ? <img src={require('../assets/images/bj1.png')} alt="" /> : <span>點擊上傳</span>}
                        <input type="file" />
                    </div>
                </div>
                <div className="tj-ship">
                    <span>wulink作品ID</span>
                    <div>
                        {uploadVideo ? <img src={require('../assets/images/bj.png')} alt="" /> : <span>點擊上傳</span>}
                        <input type="file" />
                    </div>
                </div>
                {/* <!-- 作品說明 --> */}
                <div className="tj-shuom">
                    <span>作品說明</span>
                    <textarea rows="10" cols="50" className="tj-text" placeholder="請簡單的說明一下吧..."></textarea>
                </div>
                <div className="tj-bottom">
                    <div className="tj-left">清空</div>
                    <div className="tj-right">提交預覽</div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileHome);;
