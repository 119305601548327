import React from 'react'
import styled from 'styled-components'
//components
import Eraser from '../components/Eraser'
// import ChapterCard from '../components/ChapterCard'
import Button from '../components/Button'
//assets
import curriculum from '../assets/img/Eraser__Curriculum.svg'
//router
import { Link } from 'react-router-dom'
import { get } from 'axios';
import { connect } from 'react-redux';
import { setCourse } from '../redux/Actions'
//config
import config from '../config'
import Modal from '@material-ui/core/Modal';
import '../css/chapterSlect.css'

const mapStateToProps = state => ({
  courses: state.courses,
  chapterTree: state.courses.chapters || [], // state.courses.chapters ||
});

function mapDispatchToProps(dispatch) {
  return {
    changeCourseData(data) {
      dispatch(setCourse(data));
    }
  }
}

class ChapterSelect extends React.Component {
  state = {
    loading: true,
    userId: '',
    show: false
  }

  componentDidMount = async () => {
    if (!this.props.chapterList) {
      const url = `${config.env.edu}chapter/${this.props.match.params.courseId}`; //`${config.env.server}/curriculum/id/${this.props.match.params.courseId}`;
      await get(url)
        .then(res => {
          res.data[0][0].chapters = JSON.parse(res.data[0][0].chapters);
          this.props.changeCourseData(res.data[0][0])
        })
        .catch(err => console.error(err));
      await this.setState({
        loading: false
      })
    }
    await get(`${config.env.server}getcookieuserid`).then(res => {
      if (res.data[0][0]) {
        this.setState({
          userId: this.props.courses.userid === res.data[0][0].userid,
        });
      }
    }).catch(err => {
      // window.alert(err.response.data)
    })
  }

  render() {
    //const keys = Object.keys(this.props.chapterTree);
    const keys = this.props.chapterTree; //this.props.chapterTree ||
    const { language } = this.props
    const identity = window.localStorage.identity && (JSON.parse(window.localStorage.identity) === 0 || JSON.parse(window.localStorage.identity) === 2)
    // console.log(this.props.courses.userid)
    return (
      <div className="chapter_select">
        <Eraser
          img={curriculum}
          name={language.course}
          areaname='title'
        />
        {(this.state.userId) && <div className="teacher">
          <h2>{language.identity_teacher}</h2>
          <div className="allFlex">
            {keys.map((val, index) => (
              <a className="chapter_a" href={`${config.env.basename}/tutor/chapter/${this.props.match.params.courseId}/${index}`}
                key={`course_${index}`}
                onClick={() => { window.sessionStorage.setItem("permissions", "teacher") }}>
                <img width='310' height="180" src={val.teacher.bg} title={val.title} style={{ objectFit: 'cover' }} />
                <div style={{ width: 310, padding: 16, margin: '0 auto' }}>
                  <div className="chapter_title">
                    {val.title}
                  </div>
                  <div className="chapter_info" >
                    {val.teacher.intro}
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>}
        <div className="student">
          <h2>{language.identity_student}</h2>
          <div className="allFlex">
            {keys.map((val, index) => (
              <a className="chapter_a" href={`${config.env.basename}/tutor/chapter/${this.props.match.params.courseId}/${index}`}
                key={`course_${index}`}
                onClick={() => { window.sessionStorage.setItem("permissions", "student") }}>
                <img width='310' height="180" src={val.student.bg} title={val.title} style={{ objectFit: 'cover' }}/>
                <div style={{ width: 310, padding: 16, margin: '0 auto' }}>
                  <div className="chapter_title">
                    {val.title}
                  </div>
                  <div className="chapter_info" >
                    {val.student.intro}
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
        <Link to={`${config.env.basename}/${this.props.match.params.courseId}`}
          css={`
            display:block;
            width:100%;
            text-align:center;
            text-decoration:none;
            margin-bottom:50px;
            @media screen and (max-width:1023px){
              width:50%;
            }
          `}>
          <Button
            name={language.return_prev_page}
            secondary
            css={`
              margin:0 auto;
              padding:10px 20px;
            `}
          />
        </Link>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.loading}
          onClose={() => {
            this.setState({
              loading: true
            })
          }}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <img src={require('../assets/img/loading1.gif')} style={{ width: 500, height: 300, position: 'absolute', top: 'calc(50% - 150px)', left: 'calc(50% - 250px)' }} />
            <p style={{ position: 'absolute', width: '100%', top: 'calc(50% + 75px)', textAlign: 'center', color: '#fff' }}>{language.loadings}</p>
          </div>
        </Modal>
      </div>
    )
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(ChapterSelect);
