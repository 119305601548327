import React from 'react'
import styled from 'styled-components'


const Wrapper = styled.div`
  width: 150%;
  height: ${props => props.height?props.height+'px':'100%'};
  cursor: pointer;
  grid-area:${props => props.area};
  place-self: ${props => props.place};
`;

const Col = styled.span`
  position: absolute;
  left:45%;
  top: 50%;
  height: 60%;
  transform: translate(-50%,-50%);
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
`;

const Row = styled.span`
  position: absolute;
  top: 50%;
  left:47%;
  width: 60%;
  transform: translate(-50%,-50%);
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
`;

const Circle = styled.div`
  float:left;
  position: relative;
  left: 30%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${props => props.radius};
  padding-top:calc(${props => props.radius} * 0.72);
  border-radius: 100%;
  border-width: 2px;
  border-style: solid;
  border-color: gray;
`;

const Rectangular = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 2px gray dashed;
`;


class AddButton extends React.Component {
  state = {
    color: '#3c5e8f80',
  }

  enter = e => {
    this.setState({ color: '#3c5e8fe6' });
  }

  leave = e => {
    this.setState({ color: '#3c5e8f80' });
  }

  render () {
    const { color } = this.state;
    const { click, height, area, place } = this.props;
    return (
      <Wrapper
        height={height}
        area={area}
        onMouseEnter={this.enter}
        onMouseLeave={this.leave}
        onClick={click}
        place={place}
      >
        <Rectangular borderColor={color}>
          <Circle radius={`${height/1.8}px`}>
            <Col />
            <Row />
          </Circle>
    <div css={`font-size: 0.9375rem; font-weight:bold;color: gray;position: relative; top:22%;left:33%`}>{this.props.language.add_chapter}</div>
        </Rectangular>
      </Wrapper>
    )
  }
}

export default AddButton;
