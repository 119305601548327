import * as ActionTypes from '../actions/newupload/type';

export default (state={}, action) => {
    const { type } = action;

    if (type === ActionTypes.UPDATECOURSEINFO) {
        return {
            ...state,
            course: {
              ...state.course,
              brief: action.brief,
              title: action.title,
            }
          }

    }else if(type === ActionTypes.UPDATECOURSECOVER){
        return {
            ...state,
            course:{
                ...state.course,
                cover:action.cover
            }
        }

    }else if(type === ActionTypes.UPDATECOURSEDEMO){
        return {
            ...state,
            course:{
                ...state.course,
                demo:action.demo
            }
        }

    }else if(type === ActionTypes.UPDATECOURSEADDCOUNT){
        return {
            ...state,
            course:{
                ...state.course,
                count:action.count
            }
        }

    }else if(type === ActionTypes.UPDATECOURSEBUMBER){
        return {
            ...state,
            course:{
                ...state.course,
                number:action.number
            }
        }

    }else if(type === ActionTypes.INITCHAPTERS){
        return {
            ...state,
            chapter:{
                ...state.chapter,
                initchapters:action.initchapters,
                prechaptertitles:action.prechaptertitles,
                prekeys:action.prekeys,
            }
        }
    }else if(type === ActionTypes.UPDATECHAPTERS){
        return {
            ...state,
            chapter:{
                ...state.chapter,
                chapters:action.chapters
            }
        }
    }else if(type === ActionTypes.UPDATEUPLOADER){
        return {
            ...state,
            uploader:{
                ...state.uploader,
                organize:action.organize,
                uploader:action.uploader
            }
        }
    }else{
        return state;
    }
}