import * as ActionTypes from '../actions/upload/type';

export default (state={}, action) => {
  const { type } = action;
  if (type === ActionTypes.INITCOURSEID) {
    return { ...state, courseId: action.courseId };
  } else if (type === ActionTypes.UPDATECHAPTERNUM) {
    return {
      ...state,
      course: {
        ...state.course,
        chapters: action.chapters
      }
    };
  } else if (type === ActionTypes.UPDATECHAPTER) {
    return {
      ...state,
      [action.id]:action.data
    }
  } else if (type === ActionTypes.UPDATECOURSEINFO) {
    const { brief, title } = action;
    return {
      ...state,
      course: {
        ...state.course,
        brief: brief,
        title: title,
      }
    }
  } else if( type === ActionTypes.UPDATECHAPTERCOVER){
    const {chapterId, cover} = action;
    return {
      ...state,
      [chapterId]:{
          ...state[chapterId],
          cover
        }
    }
  } else if (type === ActionTypes.UPDATECHAPTERINTRO){
    const {chapterId,intro} = action;
    return {
      ...state,
      [chapterId]:{
          ...state[chapterId],
          intro
      }
    }
  } else if(type === ActionTypes.UPDATECHAPTERSLIDES){
    const {chapterId,pdf} = action;
    return {
      ...state,
      [chapterId]:{
          ...state[chapterId],
          pdf
      }
    }
  } else if (type === ActionTypes.UPDATECOURSECOVER ) {
    return {
      ...state,
      course: {
        ...state.course,
        cover:action.cover,
      }
    }
  } else if (type === ActionTypes.UPDATECOURSEADDCOUNT ) {
    return {
      ...state,
      course: {
        ...state.course,
        count:action.count,
      }
    }
  }else if (type === ActionTypes.UPDATECOURSEBUMBER ) {
    return {
      ...state,
      course: {
        ...state.course,
        number:action.number,
      }
    }
  } else if (type === ActionTypes.UPDATECOURSEDEMO) {
    return {
      ...state,
      course: {
        ...state.course,
        demo: action.demo,
      }
    }
  } else if (type === ActionTypes.UPDATECHAPTERCLIPS) {
    const { id, key, clip } = action;
    return {
      ...state,
      [id]: {
        ...state[id],
        clips: [
          ...state[id].clips,
          key
        ]
      },
      files: {
        ...state.files,
        [key]: {
          ...clip
        }
      }
    }
  } else if (type === ActionTypes.UPDATECHAPTERATTACHMENTS){
    const { id, key, attachment } = action;
    return {
      ...state,
      [id]: {
        ...state[id],
        attachments: [
          ...state[id].attachments,
          key
        ]
      },
      files: {
        ...state.files,
        [key]: {
          ...attachment
        }
      }
    }
  }else if(type === ActionTypes.UPDATEATTACHMENTPROGRESS){
    const {key,percent} = action;
    return{
      ...state,
      files: {
        ...state.files,
        [key]: {
          ...state.files[key],
          percentage: percent
        }
      }
    }
  }else if (type === ActionTypes.UPDATECLIPPROGRESS) {
    const { key, percent } = action;
    return {
      ...state,
      files: {
        ...state.files,
        [key]: {
          ...state.files[key],
          percentage: percent
        }
      }
    }
  } else if (type === ActionTypes.UPDATECLIPSTATUS) {
      const { key, status } = action;
      return {
        ...state,
        files: {
          ...state.files,
          [key]: {
            ...state.files[key],
            status
          }
        }
      }
  } else if (type === ActionTypes.UPDATEATTACHMENTSTATUS) {
      const { key, status } = action;
      return {
        ...state,
        files: {
          ...state.files,
          [key]: {
            ...state.files[key],
            status
          }
        }
      }
  }else if (type === ActionTypes.INITCHAPTERINFO) {
    const { length, titles } = action;
    return {
      ...state,
      chapters: {
        ...state.chapters,
        titles:titles,
        length:length
      }
    }
}else {
    return state;
  }
}
