import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { TextField } from '@material-ui/core'
import _ from '../utils'

class AddItem extends React.Component {
    state = {
        lists:[
            {id:1,text:''}
        ]
    }
    ItemDebounced = _.debounce(this.props.getItemVal || function(){}, 500);
    reset=()=>{
        this.setState({
            lists:[
                {id:1,text:''}
            ]
        })
    }
    addItem = () => {
        let obj={
            id:this.state.lists.length+1,
            text:''
        }
        var list=this.state.lists;
        list.push(obj)
        this.setState({
            lists: list
        })
    }
    removeItem = (i) => {
        let list=this.state.lists
        let index=i-1;
        if(list.length===1){
            window.alert('不能再刪除了')
        }else{
            list.splice(index,1)
            this.setState({
                lists: list
            })
        }
        
    }
    //修改
    updateVal = (e,id)=> {
        e.preventDefault();
        var value = e.target.value;
        let list=this.state.lists;
        for(let i = 0;i<=list.length;i++){
            var obj=list[i]
            for(let key in obj){
                if(obj[key]===id){
                    obj.text=value
                }
            }
        }
        this.setState({
            lists: list
        })
        this.ItemDebounced(list)
      };

    render() {
        var Addlist = () => {
            var list = [];
            for(var i = 0; i < this.state.lists.length; i++) {
                let index=i+1;
                list.push(
                    <React.Fragment key={i}>
                    <TextField
                    label={'章节' + index + '名称'}
                    onChange={(e)=>this.updateVal(e,i)}
                    value={this.state.lists[i].text}
                    variant='outlined'
                ></TextField>
                    <IconButton
                        css={'margin-top:3px;'}
                        onClick={this.addItem}
                    >
                        <AddRoundedIcon />
                    </IconButton> 
                    <IconButton 
                        css={'margin-top:3px;'}
                        onClick={()=>this.removeItem(i)}
                    >
                        <DeleteRoundedIcon />
                    </IconButton>
                    <br/>
                </React.Fragment>
                
                )
            }
            return list
          }
        return (
            <div>
            
            {Addlist()}

            </div>
        )
    }
}
export default AddItem;