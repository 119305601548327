import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {ReactComponent as Delete} from '../assets/img/ProcessCard__Icon--Cross.svg'


const Wrapper = styled.div`
  padding-right:10px;
  display:grid;
  grid-template-columns:repeat(5, 1fr);
  width:100%;
  grid-template-rows:repeat(3 ,25px);
  background-color:#fff;
  grid-template-areas:
  'icon filename filename . delete'
  'icon status status . percentage'
  'icon progressBar progressBar progressBar progressBar';
  border-radius:5px;
  margin:0 0 10px 0;
`;
const Icon = styled.img`
  grid-area:icon;
  padding:10px;
`;
const Filename = styled.h3`
  grid-area:filename;
  padding:0;
  margin:0;
  align-self:end;
  justify-self:start;
`;
const Status = styled.h4`
  grid-area:status;
  padding:0;
  margin:0;
  align-self:center;
  justify-self:start;
`;

const ProgressBar = styled.div`
  grid-area:progressBar;
  height:5px;
  width:100%;
  box-sizing:border-box;
  background-color:#9B9B9B;
  align-self:center;
`;
const Indicator = styled.div`
  height:5px;
  width:${props=>props.percentage};
  box-sizing:border-box;
  background-color:#EA4D89;
  align-self:center;
`;

const Percentage = styled.p`
  grid-area:percentage;
`;

class ProcessCard extends React.Component {
  render () {
    return(
      <Wrapper id={this.props.id}>
        <Icon src={this.props.icon} width='100%' height='100%'/>
        <Filename>
          {this.props.filename}
        </Filename>
        <Status>
          {this.props.status}
        </Status>
        <ProgressBar>
          <Indicator
            percentage={(this.props.percentage || 0)  * 100+'%'}
          />
        </ProgressBar>
        <Percentage>
          {(this.props.percentage || 0) * 100 + '%'}
        </Percentage>
        {/*<Delete
          css={`
            grid-area:delete;
            height:80%;
            align-self:end;
            justify-self:end;
            `}
          onClick={this.props.cancelFile}
        />*/}
      </Wrapper>
    )
  }
}

export default ProcessCard;
