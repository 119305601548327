import * as ActionTypes from '../actions/newupload/type';

export default (state=[],action) => {
  if(action.type === ActionTypes.NEXTSTEP){
    return { activeStep:action.activeStep+1}
  }else if( action.type === ActionTypes.LASTSTEP){
    return {activeStep:action.activeStep-1}
  }if(action.type === ActionTypes.SETSTEP){
    return { activeStep: action.activeStep}
  }else{
    return state;
  }
}
