export const SETCOMPETITION = "setCompetition";
export const SETAIOT = "setAiot";
export const SETCOURSE = 'setCourse';
export const SETCHAPTER = 'setChapter';
export const SETBENNER = 'setBanner';
export const SETAPP = 'setapp';
export const SETFEATURES = 'setFeatures';
export const SETWORKSHOP = 'setWorkshop';
export const SETTHEMES = 'setThemes'
export const TOGGLEROOM = 'toggleRoom';
export const TOGGLELOGIN = 'toggleLogin';
export const TOGGLESUBSCRIBE = 'toggleSubscribe';
export const OPENUSERDWOPDWON = "openUserDropdown";
export const SCHEDULECOUNT = "scheduleCount";
export const SUCCESSCOUNT = "successCount";
