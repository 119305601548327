import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';

import { Button } from '@material-ui/core'
import Subtitle from './Subtitle'
import TitleInput from './TitleInput';
import IntroInput from './IntroInput';
import MultipleUpload from './MultipleUpload';
import Chapteroverlook from './Chapteroverlook'
import Chapteredit from './Chapteredit'
import Uploader from './Uploader'
// Images
import videoIcon from '../assets/img/Upload__Icon--video.svg'
import attchementIcon from '../assets/img/Upload__Icon--attachment.svg'

// Configuration
import config from '../config'

//Routes
import { Redirect, Link } from 'react-router-dom'
import {updateChapters} from '../actions/newupload'

// Actions
import {
  setStep,
} from '../actions/newupload'


const Wrapper = styled.div`
  margin-top: 30px;
  width: 80%;
  justify-self: center;
  display: grid;
  grid-template-columns: 10% 35% 5% 35% 10%;
  grid-template-rows: 50px 30px auto 40px auto 25px 80px;
  grid-template-areas:
  '. title title . .'
  '. font font . .'
  '. overlook overlook overlook .'
  '. divider divider divider .'
  '. chapteredit chapteredit chapteredit .'
  // '. uploader uploader uploader .'
  '. divider2 divider2 divider2 .'
  '. last . next .'
`;
const Divider = styled.hr`
  align-self: center;
  grid-area:divider;
  height: 1px;
  border: none;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0;
`;
const Divider2 = styled.hr`
  align-self: center;
  grid-area:divider2;
  height: 1px;
  border: none;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0;
`;
const mapStateToProps = (state) => {
  return {
    activeStep: state.steppers.activeStep,
    title: state.newupload.course.title,
    brief: state.newupload.course.brief,
    cover: state.newupload.course.cover,
    demo: state.newupload.course.demo,
    number: state.newupload.course.number,
    chapters: state.newupload.chapter.chapters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleSet(activeStep) {
      dispatch(setStep(activeStep))
    },
		updateChapters(chapters) {
			dispatch(updateChapters(chapters))
		},
  }
}


class ChapterUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      number:0
    }
    props.handleSet(1);
  }

  componentDidMount() {
    let data = JSON.parse(window.localStorage.obj)
		if (!data.title && !data.brief) {
			this.props.history.push('/upload')
		}
  }

  onNext = () => {
    window.localStorage['arr'] = JSON.stringify(this.props.chapters.length!==0?this.props.chapters:JSON.parse(window.localStorage.arr))
    this.props.history.push('/upload/chapter/check');

  }
  onLast = () => {  
    this.props.updateChapters(JSON.parse(window.localStorage.arr))
    this.props.history.push('/upload/chapter/name');
  }
  getNumber = e => {
    this.setState({
      number:e
    })
  }
  render() {
    const {language} = this.props
    return (
      <Wrapper>
        <Subtitle
          areaname='title'
          name={`${language.upload_course_content}:${language.chapter_data_upload}`}
        />
        {/* <Font>點擊下欄中任意一個章節,對該章節的資料進行完善。</Font> */}
        <Chapteroverlook language={language}  history={this.props.history} number={this.state.number} getNumber={this.getNumber}></Chapteroverlook>
        <Divider></Divider>
        <Chapteredit language={language} history={this.props.history} getNumber={this.getNumber} number={this.state.number}></Chapteredit>
        {/*<Uploader></Uploader>*/}
        <Divider2></Divider2>
        <div
          css={`
						text-decoration: none;
						grid-area:last
						width:60%;
						place-self: center;
					`}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={this.onLast}
          >
            {language.prev}
					</Button>
        </div>
        <div
          css={`
						text-decoration: none;
						grid-area:next
						width:60%;
						place-self: center;
					`}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={this.onNext}
          >
            {language.next}
					</Button>
        </div>
      </Wrapper>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterUpload);
