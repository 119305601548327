import * as ActionTypes from './ActionTypes';

export const setCompetitions = data => ({
  type: ActionTypes.SETCOMPETITION,
  competitions: data,
});

export const setCourse = info => ({
  type: ActionTypes.SETCOURSE,
  courseInfo: info
});

export const setAiots = data => ({
  type: ActionTypes.SETAIOT,
  aiots: data,
});

export const setChapter = (chapterId, data) => ({
  type: ActionTypes.SETCHAPTER,
  chapterData: data,
  chapterId: chapterId
});

export const setBanners = data => ({
  type: ActionTypes.SETBENNER,
  banners: data,
});

export const setApp = data => ({
  type: ActionTypes.SETAPP,
  app: data,
});


export const setFeatures = data => ({
  type: ActionTypes.SETFEATURES,
  features: data,
});
export const setThemes = data => ({
  type:ActionTypes.SETTHEMES,
  themes:data,
})
export const setWorkshops = data => ({
  type: ActionTypes.SETWORKSHOP,
  workshops: data,
});

export const toggleRoom = state => ({
  type: ActionTypes.TOGGLEROOM,
  roomModalIsOpen: state,
});

export const toggleSubscribe = state => ({
  type: ActionTypes.TOGGLESUBSCRIBE,
  subscribeModalIsOpen: state,
});

export const toggleUserDropdown = state => ({
  type: ActionTypes.OPENUSERDWOPDWON,
  userDropdownIsOpen: state
});

export const toggleLogin = state => ({
  type: ActionTypes.TOGGLELOGIN,
  loginModalIsOpen: state,
});

export const scheduleCounts = state => ({
  type: ActionTypes.SCHEDULECOUNT,
  scheduleCount: state,
});

export const successCounts = state => ({
  type: ActionTypes.SUCCESSCOUNT,
  successCount: state,
});