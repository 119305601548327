import * as ActionTypes from '../redux/ActionTypes';


export default (state=[], action) => {
	//console.log(action);
	if (action.type === ActionTypes.SETAPP) {
		return [ ...state, ...action.app ]
	} else {
		return state;
	}
}

