import React from 'react'
import { Button } from '@material-ui/core'


class FileButton extends React.Component {
  render() {
    const { bid, accept, change, variant, size, fullWidth, height,
      color, classes, children } = this.props;
    return (
      <div css={`height:${height};`}>
        <input
          id={bid}
          type="file"
          accept={accept}
          css={`display: none;`}
          onChange={change}
          multiple
        />
        <label htmlFor={bid}>
          <Button
            variant={variant}
            size={size}
            fullWidth={fullWidth}
            component="span"
            color={color}
            css={`height:${height};`}
            className={classes? classes.button: ''}
          >
            {children || '上傳'}
          </Button>
        </label>
      </div>
    );
  }
}

export default FileButton;
