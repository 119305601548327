import React from 'react'
import PropTypes from 'prop-types'

class RoomCreated extends React.Component {
  render () {
    return(
      <div></div>
    )
  }
}

export default RoomCreated;
