import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import axios from 'axios'
import qs from 'qs';
import styled from 'styled-components'
//components
import Carousel from '../components/Carousel'
import Eraser from '../components/Eraser'
import CourseCard from '../components/CourseCard'
import ThemeBullet from '../components/ThemeBullet'
//assets
import curriculum from '../assets/img/Eraser__Curriculum.svg'
import { setApp, setBanners, setFeatures, setWorkshops, setThemes, setCompetitions, setAiots, toggleUserDropdown } from '../redux/Actions'
import config from '../config'
import Modal from '@material-ui/core/Modal';
import '../css/Homepage.css';


const Wrapper = styled.div`
  grid-area:${props => props.areaname};
  display:grid;
  text-align: center;
  grid-template-columns:repeat(12, calc(100% / 12));
  grid-template-rows:auto auto auto auto 50px;
  grid-gap:50px 0;
  width:100%;
  grid-template-areas:
  'banner banner banner banner banner banner banner banner banner banner banner banner'
  '. label label label label label label label label label label .'
  '. school school school school school school school school school school .'
  '. app app app app app app app app app app .'
  '. . . . . . . . . . . .';
  //small device
  @media screen and (max-width:1023px){
    grid-template-areas:
    'banner banner banner banner banner banner banner banner banner banner banner banner'
    '. label label label label label label label label label label .'
    '. school school school school school school school school school school .'
    '. app app app app app app app app app app .'
    '. . . . . . . . . . . .';
  }
`;

const App = styled.div`
  grid-area:app;
`
const Label = styled.div`
  grid-area:label;
`
const School = styled.div`
  grid-area:school`

const TypeContent = styled.div`
  display: flex;
    align-items: center;
        margin-bottom: .5rem;
        width:100%;
        overflow:auto;
        padding-bottom: .5rem;
`

const TypeBox = styled.div`
  display: flex;
    align-items: center;
        margin-bottom: .5rem;
`

const TypeTitle = styled.div`
    font-size: 1.3rem;
    font-weight: 600;
    min-width: 10rem;
    text-align: left;
      @media screen and (max-width:1080px){
        font-size: 1.1rem;
        min-width: 7rem;
  }
`

const TypeChild = styled.div`
font-size: 1.2rem;
    font-weight: 100;
    text-align: center;
    padding: .3rem 1rem;
    border: 1px solid #eee;
    cursor: pointer;
    margin-right: .5rem;
    border-radius: 3px;
    min-width: 8rem;
    transition: all .1s;
    @media screen and (max-width:1080px){
        font-size: 1rem;
        min-width: 6rem;
  }
`
const CourseCardContainer = styled.div`
  grid-area:${props => props.areaname};
  display:grid;
  grid-template-columns:1fr 1fr 1fr 1fr;
  grid-gap:30px 20px;
  @media screen and (max-width:1023px){
    grid-template-columns:1fr 1fr;
  }
`

// const CourseCardContainer = styled.div`
//   grid-area:${props => props.areaname};
//   // display:grid;
//   // grid-template-columns:1fr 1fr 1fr 1fr;
//   // grid-gap:30px 20px;
//   @media screen and (max-width:1023px){
//     grid-template-columns:1fr 1fr;
//   }
// `

const mapStateToProps = state => ({
  banners: state.banners,
  app: state.app
});

const mapDispatchToProps = dispatch => ({
  fetchBanners(data) {
    dispatch(setBanners(data));
  },
  fetchApps(data) {
    dispatch(setApp(data));
  },
  openUserDropdown(state) {
    dispatch(toggleUserDropdown(state));
  },
});

const filterProps = list => {
  const result = [];
  list.forEach(val => {
    const { date, __v, _id, ...obj } = val;
    result.push(obj);
  });
  return result;
};

class Homepage extends React.Component {
  state = {
    "bannerList": null, "app": [], loading: true, typeArray: [], typeChildren: [], checkArray: [], courseArray: [],
    typea: [],
    typeb: [],
    typec: [],
    typed: [],
    typee: [],
    typeCArray: [],
    courseZArray: [],
    schoolName:'',
    schoolcourseList:[]
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "首页"
    const { banners, app } = this.props;
    const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
    let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
    this.getMySchool()
    if (!(banners.length)) {
      axios.get(`${config.env.edu}list`)
        .then(res => {
          for (let index = 0; index < res.data[1].length; index++) {
            res.data[1][index].course = [];
            for (let index1 = 0; index1 < res.data[2].length; index1++) {
              if (res.data[1][index].id === res.data[2][index1].appid) {
                res.data[1][index].course.push(res.data[2][index1]);
              }
            }
          }
          this.setState({
            "bannerList": res.data[0],
            "app": res.data[1],
            loading: false
          })
          // console.log(res.data[1]);
          if (!banners.length)
            this.props.fetchBanners(filterProps(res.data[0]));
          this.props.fetchApps(filterProps((res.data[1])));
        })
        .catch(err => console.log(err));
    } else {
      this.setState({
        loading: false
      })
    }
    this.getData('0');
    
    if(arr){
      axios.get(`${config.env.server}getcookieuserid`).then(res => {
        if (res.data[0][0]) {
          this.getMySchool(res.data[0][0].userid)
        }
      }).catch(err => {
        // window.alert(err.response.data)
      })
    }
  }

  getData = (pid) => {
    let params = {
      pid: pid
    }
    axios.post(`${config.env.edu}getAllType`, qs.stringify(params)).then(res => {
      console.log(res)
      let _ta = []
      var typeArray = res.data[0]
      var res = res.data[1]
      for (let i = 0; i < typeArray.length; i++) {
        _ta[typeArray[i].id] = []
        for (let j = 0; j < res.length; j++) {
          if (res[j].pid == typeArray[i].id) {
            _ta[typeArray[i].id].push(res[j])
          }
        }
      }
      console.log(_ta);
      this.setState({
        typeArray: typeArray,
        typeChildren: _ta
      })
    })
  }

  getMySchool(id){
    // https://api.edu.cocorobo.hk/edu/profile/selectcoursebyuid/20b1517a-ddd1-11e9-b291-028edca3b798/1/12/
    // https://api.edu.cocorobo.hk/edu/admin/userinfo/userinfoById/20b1517a-ddd1-11e9-b291-028edca3b798/
    let params ={}
    let _this = this
    console.log(_this,'11111111111111')
    // axios.post(`${config.env.edu}admin/userinfo/userinfoById/${id}/`).then(res=>{
    //   console.log('selectcoursebyuid',res)
    //   if(res.data[0]){
    //     _this.setState({
    //       schoolName:res.data[0][0].name
    //     })
    //     axios.get(`${config.env.edu}profile/schoolcourse/${id}/`).then(d=>{
    //       console.log('schoolcourse', d)
    //       _this.setState({
    //         schoolcourseList:d.data[0]
    //       })
    //     })
    //   }
    // })
  }

  check = (id, type, pid, tcn) => {
    let { checkArray, typeChildren, typeArray, typea, typeb, typec, typed, typee, typeCArray } = this.state
    if (type == 2) {
      if (typee.indexOf(id) !== -1) {
        checkArray.splice(checkArray.indexOf(id), 1)
        typee.splice(typee.indexOf(id), 1)
        for (var i = 0; i < typeCArray.length; i++) {
          if (typeCArray[i].id == id) {
            typeCArray.splice(i, 1)
          }
        }
      } else {
        for (var i in typeChildren[id]) {
          if (checkArray.indexOf(typeChildren[id][i].id) != -1) {
            checkArray.splice(checkArray.indexOf(typeChildren[id][i].id), 1)
            for (var j = 0; j < typeCArray.length; j++) {
              if (typeCArray[j].id == typeChildren[id][i].id) {
                typeCArray.splice(j, 1)
              }
            }
            for (var j = 0; j < typeArray.length; j++) {
              if (typeArray[j].id == id) {
                if (j == 0) {
                  typea.splice(typea.indexOf(typeChildren[id][i].id), 1)
                  break;
                }
                if (j == 1) {
                  typeb.splice(typeb.indexOf(typeChildren[id][i].id), 1)
                  break;
                }
                if (j == 2) {
                  typec.splice(typec.indexOf(typeChildren[id][i].id), 1)
                  break;
                }
                if (j == 3) {
                  typed.splice(typed.indexOf(typeChildren[id][i].id), 1)
                  break;
                }
              }
            }
          }
        }
        // typee = []
        // checkArray = []
        // typea = []
        // typeb = []
        // typec = []
        // typed = []
        // typeCArray = []
        typeCArray.push({ name: tcn, type: '4', id: id })
        typee.push(id)
        checkArray.push(id)
      }
    } else {
      if (checkArray.indexOf(id) !== -1) {
        checkArray.splice(checkArray.indexOf(id), 1)
        for (let i = 0; i < typeArray.length; i++) {
          if (typeArray[i].id == pid) {
            if (i == 0) {
              typea.splice(typea.indexOf(id), 1)
              break;
            }
            if (i == 1) {
              typeb.splice(typeb.indexOf(id), 1)
              break;
            }
            if (i == 2) {
              typec.splice(typec.indexOf(id), 1)
              break;
            }
            if (i == 3) {
              typed.splice(typed.indexOf(id), 1)
              break;
            }
          }
        }
        for (var i = 0; i < typeCArray.length; i++) {
          if (typeCArray[i].id == id) {
            typeCArray.splice(i, 1)
          }
        }
      } else {
        // for (var i = 0; i < typee.length; i++) {
        //   checkArray.splice(checkArray.indexOf(typee[i]), 1)
        // }
        // for (var i = 0; i < typeCArray.length; i++) {
        //   for (var j = 0; j < typee.length; j++) {
        //     if (typeCArray[i].id == typee[j]) {
        //       typeCArray.splice(i, 1)
        //     }
        //   }
        // }
        if (checkArray.indexOf(pid) != -1) {
          typee.splice(typee.indexOf(pid), 1)
          checkArray.splice(checkArray.indexOf(pid), 1)
          for (var j = 0; j < typeCArray.length; j++) {
            if (typeCArray[j].id == pid) {
              typeCArray.splice(j, 1)
            }
          }
        }
        // for (let i = 0; i < typeArray.length; i++) {
        //   if (checkArray.indexOf(typeArray[i].id) != -1) {
        //     checkArray.splice(checkArray.indexOf(typeArray[i].id), 1)
        //   }
        // }
        for (let i = 0; i < typeArray.length; i++) {
          if (typeArray[i].id == pid) {
            if (i == 0) {
              for (var k = 0; k < typeCArray.length; k++) {
                for (var j = 0; j < typea.length; j++) {
                  if (typeCArray[k].id == typea[j]) {
                    typeCArray.splice(k, 1)
                  }
                }
              }

              for (var j = 0; j < typea.length; j++) {
                checkArray.splice(checkArray.indexOf(typea[j]), 1)
              }
              typea = []
              typea.push(id)
              typeCArray.push({ name: tcn, type: '0', id: id })
              break;
            } else if (i == 1) {
              for (var k = 0; k < typeCArray.length; k++) {
                for (var j = 0; j < typeb.length; j++) {
                  if (typeCArray[k].id == typeb[j]) {
                    typeCArray.splice(k, 1)
                  }
                }
              }
              for (var j = 0; j < typeb.length; j++) {
                checkArray.splice(checkArray.indexOf(typeb[j]), 1)
              }
              typeb = []
              typeb.push(id)
              typeCArray.push({ name: tcn, type: '1', id: id })
              break;
            } else if (i == 2) {
              for (var k = 0; k < typeCArray.length;k++) {
                for (var j = 0; j < typec.length; j++) {
                  if (typeCArray[k].id == typec[j]) {
                    typeCArray.splice(k, 1)
                  }
                }
              }
              for (var j = 0; j < typec.length; j++) {
                checkArray.splice(checkArray.indexOf(typec[j]), 1)
              }
              typec = []
              typec.push(id)
              typeCArray.push({ name: tcn, type: '2', id: id })
              break;
            } else if (i == 3) {
              for (var k = 0; k < typeCArray.length; k++) {
                for (var j = 0; j < typed.length; j++) {
                  if (typeCArray[k].id == typed[j]) {
                    typeCArray.splice(k, 1)
                  }
                }
              }
              for (var j = 0; j < typed.length; j++) {
                checkArray.splice(checkArray.indexOf(typed[j]), 1)
              }
              typed = []
              typed.push(id)
              typeCArray.push({ name: tcn, type: '3', id: id })
              break;
            }
          }
        }
        // typee = []
        checkArray.push(id)
      }
    }

    this.setState({
      checkArray: checkArray,
      typea: typea,
      typeb: typeb,
      typec: typec,
      typed: typed,
      typee: typee,
      typeCArray: typeCArray
    }, () => {
      this.getCourse();
    })
  }
  // componentWillUnmount = () => {
  //   this.props.openUserDropdown(true)
  // }

  getCourse = () => {
    this.setState({
      loading: true
    })
    let { checkArray, typea, typeb, typec, typed, typee } = this.state
    if (checkArray.length == 0) {
      this.setState({
        courseArray: []
      })
      this.setState({
        loading: false
      })
      return;
    }
    let params = {
      // checkArray: checkArray.join(",")
      typea: typea.join(","),
      typeb: typeb.join(","),
      typec: typec.join(","),
      typed: typed.join(","),
      typee: typee.join(","),
    }
    axios.post(`${config.env.edu}getCourseByType`, qs.stringify(params)).then(res => {
      this.setState({
        loading: false
      })
      console.log(res)


      // for (let index = 0; index < res.data[1].length; index++) {
      //   res.data[1][index].course = [];
      //   for (let index1 = 0; index1 < res.data[2].length; index1++) {
      //     if (res.data[1][index].id === res.data[2][index1].appid) {
      //       // res.data[1][index].course.push(res.data[2][index1]);
      //       res.data[2][index1].course = [];
      //       for (let z = 0; z < res.data[0].length; z++) {
      //         if (res.data[0][z].appid === res.data[2][index1].id) {
      //           res.data[2][index1].course.push(res.data[0][z]);
      //         }
      //       }
      //       if (res.data[2][index1].course.length != 0) {
      //         res.data[1][index].course.push(res.data[2][index1]);
      //       }
      //     }
      //   }
      // }
      for (let index = 0; index < res.data[1].length; index++) {
        res.data[1][index].course = [];
        for (let index1 = 0; index1 < res.data[0].length; index1++) {
          if (res.data[1][index].id === res.data[0][index1].appid) {
            res.data[1][index].course.push(res.data[0][index1]);
          }
        }
      }
      for (let index = 0; index < res.data[1].length; index++) {
        if (res.data[1][index].course.length == 0) {
          delete res.data[1][index]
        }
      }
      this.setState({
        courseZArray: res.data[0],
        courseArray: res.data[1]
      })
    })
  }

  deleteA = (id, index, type) => {
    let { checkArray, typea, typeb, typec, typed, typee, typeCArray } = this.state
    if (type == '0') {
      typea.splice(typea.indexOf(id), 1)
    } else if (type == '1') {
      typeb.splice(typeb.indexOf(id), 1)
    }
    else if (type == '2') {
      typec.splice(typec.indexOf(id), 1)
    }
    else if (type == '3') {
      typed.splice(typed.indexOf(id), 1)
    }
    else if (type == '4') {
      typee.splice(typee.indexOf(id), 1)
    }
    checkArray.splice(checkArray.indexOf(id), 1)
    typeCArray.splice(index, 1)
    this.setState({
      checkArray: checkArray,
      typea: typea,
      typeb: typeb,
      typec: typec,
      typed: typed,
      typee: typee,
      typeCArray: typeCArray
    }, () => {
      this.getCourse();
    })
  }

  render() {
    const { banners, app, language,userid } = this.props
    const { typeArray, typeChildren, checkArray, courseArray, typee, typeCArray, courseZArray,schoolcourseList } = this.state
    console.log(userid)
    return (
      <div>
        <Wrapper areaname={this.props.areaname}>
          <Carousel areaname='banner' imgs={banners} />
          <Label>
            <TypeContent className="divOw">
              {
                typeCArray.map((x, y) => (
                  <div className="hp_typeC">
                    <span>{x.name}</span>
                    <span onClick={() => { this.deleteA(x.id, y, x.type) }}>×</span>
                  </div>
                ))
              }
            </TypeContent>
            {
              typeArray.map((x, y) => (
                <TypeBox key={y}>
                  <TypeTitle>{x.name}</TypeTitle>
                  <TypeChild className='typechild' css={checkArray.indexOf(x.id) != -1 ? `background:#6e98ed;color:#fff` : ``} onClick={() => { this.check(x.id, 2, '', x.name) }}>全部</TypeChild>
                  {
                    typeChildren[x.id] && typeChildren[x.id].length > 0 && typeChildren[x.id].map((item, y1) => (
                      <TypeChild className='typechild' key={y + '-' + y1} css={checkArray.indexOf(item.id) != -1 ? `background:#6e98ed;color:#fff` : ``} onClick={() => { this.check(item.id, 1, x.id, item.name) }}>{item.name}</TypeChild>
                    ))
                  }
                </TypeBox>
              ))
            }
          </Label>
          
          {userid&&<School>
            <Eraser img={'http://beta.edu.cocorobo.hk//course/static/media/Eraser__Curriculum.1104fba4.svg'}
                    name={this.state.schoolName}
                    areaname='title' />
            <CourseCardContainer>
              {schoolcourseList.length>0&&schoolcourseList.map(x=>(
                <Link key={x.id} to={`${config.env.basename}/${x.number}`} css={`text-decoration:none;height:100%;position:relative;`}>
                {x.state === 0 ? <span style={{position:'absolute',top:15,right:47, background: '#3369e7', color: '#fff', borderRadius: 5, padding: '3px 7px', fontSize: 14, boxShadow: '0 2px 6px 0 rgba(51,105,231,.4)',marginLeft:10,zIndex:1 }}>{language.forthcoming}</span> : ''}
                <CourseCard
                  key={x.id}
                  poster={x.poster}
                  title={x.title}
                  intro={x.uploader}
                  courseId={x.number}
                  state={x.state}
                />
              </Link>
              ))}
            </CourseCardContainer>
          </School>}
          <App>
            {courseArray && checkArray.length > 0 ?
              <div>
                {courseZArray.length > 0 ? courseArray.map((x, y) => (
                <React.Fragment key={y}>
                  <Eraser
                    img={'http://beta.edu.cocorobo.hk/' + x.img}
                    name={x.name}
                    areaname='title'
                  />
                  <CourseCardContainer>
                    {x.course.map((x, y) => (
                      <Link key={x.id} to={`${config.env.basename}/${x.number}`} css={`text-decoration:none;height:100%;position:relative;`}>
                        {x.state === 0 ? <span style={{position:'absolute',top:15,right:47, background: '#3369e7', color: '#fff', borderRadius: 5, padding: '3px 7px', fontSize: 14, boxShadow: '0 2px 6px 0 rgba(51,105,231,.4)',marginLeft:10,zIndex:1 }}>{language.forthcoming}</span> : ''}
                        <CourseCard
                          key={x.id}
                          poster={x.poster}
                          title={x.title}
                          intro={x.uploader}
                          courseId={x.number}
                          state={x.state}
                        />
                      </Link>
                    ))}
                  </CourseCardContainer>
                  </React.Fragment>
                )) : <img src={require('../assets/img/none.png')} />}
              </div>
              :
              app.map((x, y) => (
                <React.Fragment key={y}>
                  <Eraser
                    img={'http://beta.edu.cocorobo.hk/' + x.img}
                    name={x.name}
                    areaname='title'
                  />
                  <CourseCardContainer>
                    {x.course.map((x, y) => (
                      <Link key={x.id} to={`${config.env.basename}/${x.number}`} css={`text-decoration:none;height:100%;position:relative;`}>
                        {x.state === 0 ? <span style={{position:'absolute',top:15,right:47, background: '#3369e7', color: '#fff', borderRadius: 5, padding: '3px 7px', fontSize: 14, boxShadow: '0 2px 6px 0 rgba(51,105,231,.4)',marginLeft:10,zIndex:1 }}>{language.forthcoming}</span> : ''}
                        <CourseCard
                          key={x.id}
                          poster={x.poster}
                          title={x.title}
                          intro={x.uploader}
                          courseId={x.number}
                          state={x.state}
                        />
                      </Link>
                    ))}
                  </CourseCardContainer>
                </React.Fragment>
              ))}
          </App>
        </Wrapper>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.loading}
          onClose={() => {
            this.setState({
              loading: true
            })
          }}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <img src={require('../assets/img/loading1.gif')} style={{ width: 500, height: 300, position: 'absolute', top: 'calc(50% - 150px)', left: 'calc(50% - 250px)' }} />
            <p style={{ position: 'absolute', width: '100%', top: 'calc(50% + 75px)', textAlign: 'center', color: '#fff' }}>{language.loadings}</p>
          </div>
        </Modal>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
