import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import bannersReducer from '../reducers/bannerReducer';
import appReducer from '../reducers/appReducer';
import featuresReducer from '../reducers/featureReducer';
import workshopsReducer from '../reducers/workshopReducer';
import themesReducer from '../reducers/themeReducer';
import coursesReducer from '../reducers/courseReducer';
import chaptersReducer from '../reducers/chapterReducer';
import modalsReducer from '../reducers/modalReducer';
import userReducer from '../reducers/userReducer';
import steppersReducer from '../reducers/steppersReducer'
import uploadsReducer from '../reducers/uploadReducer'
import createReducer from '../reducers/createReducer'
import competitionsReducer from '../reducers/competitionsReducer'
import aiotReducer from '../reducers/aiotReducer'
import newuploadReducer from '../reducers/newuploadReducer'

const reducers = combineReducers({
  app: appReducer,
  banners: bannersReducer,
  features: featuresReducer,
  aiots: aiotReducer,
  workshops: workshopsReducer,
  competitions: competitionsReducer,
  courses: coursesReducer,
  chapters: chaptersReducer,
  modals: modalsReducer,
  user: userReducer,
  steppers: steppersReducer,
  themes: themesReducer,
  upload: uploadsReducer,
  create: createReducer,
  newupload: newuploadReducer
});

const init = {
  modals: {
    roomModalIsOpen: false,
    loginModalIsOpen: false,
    subscribeModalIsOpen: false,
    // 進度條
    scheduleCount: 0, // 上傳文件的总数
    successCount: 0,  // 上傳文件成功的总数
  },
  steppers: {
    activeStep: 0
  },
  upload: {
    courseId: null,
    course: {
      title: null,
      brief: null,
      cover: null,
      demo: null,
      chapters: 0,
    },
    chapters: {
      length: 0,
      titles: []
    },
    files: {},
  },
  newupload: {
    course: {
      courseId: null,
      title: null,
      brief: null,
      cover: [],
      demo: [],
      count: 0
    },
    chapter: {
      initchapters: [],
      prechaptertitles: [{ title: '', id: 0, show: false }],
      prekeys: [0],
      chaptersinfo: [],
      chapters: []
    },
    uploader: {
      organize: '',
      uploader: ''
    }
  },
  create: {
    roomId: null,
    slides: {
      name: null,
      path: null,
    },
    clips: [],
    attachments: [],
    files: {},
    title: null,
    brief: null,
    queue: 0
  }
}

const middlewares = [thunk];

const storeEnhancers = compose(
  applyMiddleware(...middlewares),
  (window && window.devToolsExtension) ?
    window.devToolsExtension() : (f) => f,
);

const store = createStore(reducers, init, storeEnhancers);

export default store;
