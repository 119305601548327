import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`

`;
const Title = styled.h2`
  color:#50C1C3;
`;
class ThemeBullet extends React.Component {
  render () {
    return(
      <Wrapper>
        <img src={this.props.img} width='100%' alt='ThemeBullet' />
        <Title>{this.props.name}</Title>
      </Wrapper>
    )
  }
}

export default ThemeBullet;
