import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
//components
import ProcessCard from './ProcessCard'



const Wrapper =  styled.div`
  height:200px;
  width:100%;
  padding:10px;
  background-color:#EBEBEB;
  border-radius:5px;
  overflow-y:scroll;
`;

class ProcessList extends React.Component {
  render () {
    const { keys, files, icon } = this.props;
    return(
      <Wrapper>
        {keys && keys.map(v => (
          <ProcessCard
            id={v}
            key={v}
            icon={icon}
            filename={files[v].filename}
            percentage={files[v].percentage}
            status={files[v].status}
            cancelFile = {this.props.cancelFile()}
          />)
        )}
      </Wrapper>
    )
  }
}

export default ProcessList;
