const _ = {};

_.now = Date.now || function () {
  return new Date().getTime();
};

_.debounce = function(fn, wait, immediate=false) {
  let timeout, args, context, timestamp, result;

  const later = () => {
    const last = _.now() - timestamp;

    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      if (!immediate) {
        result = fn.apply(context, args);
        context = args = null;
      }
    }
  }

  return function () {
    context = this;
    args = arguments;
    timestamp = _.now();
    const callNow = immediate && !timeout;
    if (!timeout) {
      timeout = setTimeout(later, wait);
    }
    if (callNow) {
      result = fn.apply(context, args);
      context = args = null;
    }
    
    return result;
  }
};

_.throttle = (fn, wait, options={}) => {
  let timeout=null, previous=0, args, context, result;

  const later = () => {
    previous = options.leading === false? 0: _.now();
    timeout = null;
    result = fn.apply(context, args);
    context = args = null;
  }

  return function () {
    let now = _.now();

    if (!previous && options.leading === false) {
      previous = now;
    }

    let remaining = wait - (now - previous);
    context = this;
    args = arguments;

    if (remaining <= 0 && remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = fn.apply(context, args);
      context = args = null;
    } else if (!timeout && options.tralling !== false) {
      timeout = setTimeout(later, remaining);
    }

    return result;
  }
};

_.string = (num, stack=[]) => {
  const chars = "abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789";
  const arr = [];
  let res = '';
  num = parseInt(num);
  while (res === '' || stack.indexOf(res) < 0) {
    for (let i = 0; i < num; i++)
      arr.push(chars[parseInt(Math.random()*chars.length)]);
    res = arr.join('');
    stack.push(res);
	}
	
  return res;
};


export default _;
