import React from 'react'
import { connect } from 'react-redux'
import '../css/login.css';
import axios from 'axios';
import qs from 'qs';
import config from '../config'
import { toggleRoom, toggleLogin, toggleUserDropdown } from '../redux/Actions';

const mapDispatchToProps = (dispatch) => ({
  openRoomModal(state) {
    dispatch(toggleRoom(state));
  },
  toggleLogin(state) {
    dispatch(toggleLogin(state));
  },
  openUserDropdown(state) {
    dispatch(toggleUserDropdown(state));
  },
});
const mapStateToProps = state => {
  const { profile } = state.user;
  return {
    loginModalIsOpen: state.modals.loginModalIsOpen,
    userDropdownIsOpen: state.modals.userDropdownIsOpen,
    account: profile && profile.account,
    name: profile && profile.name
  };
};
const regEmail = new RegExp("^[A-Za-z0-9_.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$");
const regpass = new RegExp(/^(?=.*\d)(?=.*[A-Za-z]).{6,}$/);

class passWord extends React.Component {
  // const [completed, setCompleted] = useState(0)
  constructor(props) {
    super(props)
    this.state = {
        isShow:true,
        email:'',   // 郵箱
        passWord:'',    // 密碼
        clickShow:true, // 點擊後自毀
    }
  }

  componentDidMount() {
    if(this.QueryString('email')&&this.QueryString('key')){
      this.setState({
        isShow:false
      })
    }
  }

  // 截取某個字符串
  QueryString=(item)=>{
    var sValue = window.location.search.match(new RegExp("[\?\&]" + item + "=([^\&]*)(\&?)", "i"));
    return sValue ? sValue[1] : sValue;
  }
  
  updateEmail = ()=>{
    const {email,clickShow} = this.state
    let str = email.replace(/(^\s*)|(\s*$)/g,"")
    if(!clickShow){
      return
    }
    if(!regEmail.test(str)){
        window.alert(this.props.language.email_regular);
        return false;
    }
    this.setState({
      clickShow:false
    })
    axios.post(`${config.env.server}emailupdate`,qs.stringify({email:str})).then(res=>{
      window.U.Alert(this.props.language.send_email)
      this.setState({
        clickShow:false
      })
    }).catch(err=>{
      this.setState({
        clickShow:true
      })
      if(err.response.data){
        window.U.Alert(err.response.data)
      }
    })
  }

  submit = () =>{
    const {passWord} = this.state
    const {language} = this.props
    let str = passWord.replace(/(^\s*)|(\s*$)/g,"")
    if(!regpass.test(str)){
        window.alert(language.password_regular);
        return false;
    }
    // `${this.QueryString('email')},${this.QueryString('key')},${str}`
    let mode = {
      username:this.QueryString('email'),
      activekey:this.QueryString('key'),
      password:str
    }
    axios.post(`${config.env.server}updatepassword`,qs.stringify(mode)).then(res=>{
      if(res.data === 1){
        window.U.Alert(language.change_password_successful)
        this.props.history.push('/login')
      }
    }).catch(res=>{

    })
  }

  render() {
    const { isShow,clickShow } = this.state
    const {language} = this.props
    return (
      <div className="password" >
          {isShow?<div class='email'>
              <p>{language.forget_password_email}</p>
              <input type='text' placeholder={language.forget_email_input} onChange={e=>{this.setState({email:e.target.value})}} />
              <button className={clickShow?'':'back'} onClick={this.updateEmail}>{language.next}</button>
          </div>:<div class='email'>
              <p>{language.input_change_password}</p>
              <input type='text' placeholder={language.input_new_password} onChange={e=>{this.setState({passWord:e.target.value})}} />
              <button onClick={this.submit}>{language.confirm_change}</button>
          </div>}
          
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(passWord);;
