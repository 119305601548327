export const LASTSTEP = 'lastStep';
export const NEXTSTEP = 'nextStep';
export const SETSTEP = 'setStep';

export const UPDATECOURSEBUMBER = 'updateCoursenumber'
export const UPDATECOURSEINFO= 'updateCourseInfo';
export const UPDATECOURSECOVER = 'updateCourseCover';
export const UPDATECOURSEDEMO = 'updateCourseDemo';
export const UPDATECOURSEADDCOUNT = 'updateCourseAddCount';


export const INITCHAPTERS= 'initChapters';
export const UPDATECHAPTERS= 'updateChapters';
export const UPDATEUPLOADER= 'updateUploader';