import { create } from 'axios'
import * as ActionTypes from './type';
import _ from '../../utils'
import config from '../../config'


const ajax2utils = create({
  baseURL: `${config.env.origin}/utils`,
  headers: {
    'Authorization': 'yezicoco'
  },
  withCredentials: true,
});

const ajax2room = create({
  baseURL: `${config.env.server}/room`,
  headers: {
    'Authorization': 'yezicoco'
  },
  withCredentials: true,
});


// ------ room actions ------ //
export const initRoomId = roomId => ({
  type: ActionTypes.INITROOMID,
  roomId,
});

export const createRoomId = (callback) => (dispatch) => {
  ajax2room({
    method: 'post',
    url: '/init',
  })
    .then(response => {
      const { roomId } = response.data;
      dispatch(initRoomId(roomId));
      console.log('init complete, roomId is ' + roomId);
      callback(roomId);
    })
    .catch(err => console.log(err));
};

export const updateRoomInfo = (title, brief) => ({
  type:ActionTypes.UPDATEROOMINFO,
  title,
  brief,
});


// ------ slide actions ------ //
export const updateRoomSlides = (roomId, name,path) => ({
  type: ActionTypes.UPDATEROOMSLIDES,
  roomId,
  slides: {
    name:name,
    path:path
  }
});

export const uploadRoomSlides = (roomId, formData, fileInfo) => dispatch => {
  dispatch(updatePendingQueue(true));
  ajax2utils({
    method: 'post',
    url: '/create/pdf',
    data: formData,
  })
    .then(() => {
      dispatch(updateRoomSlides(roomId, fileInfo.name, fileInfo.path));
      console.log('Upload slides complete.')
    })
    .catch(err => console.log(err))
    .then(() => dispatch(updatePendingQueue(false)));
}


// ------ clip actions ------ //
const updateRoomClipProgress = (key, percent) => ({
  key, percent,
  type: ActionTypes.UPDATEROOMCLIPPROGRESS
});

const updateClipStatus = (key, status) => ({
  key, status,
  type: ActionTypes.UPDATEROOMCLIPSTATUS
});

export const updateRoomClip = (id,key,clip) => ({
  id, key, clip,
  type:ActionTypes.UPDATEROOMCLIP,
});

export const uploadRoomClip = (file, roomId) => (dispatch, getState) => {
  const data = new FormData();
  const clips = getState().create.clips.concat();
  const key = _.string(6, clips);
  const data2Store = {
    filename: file.name,
    status: 'Pending',
    percentage: 0,
  };

  dispatch(updateRoomClip(roomId, key, data2Store));

  data.append('roomId', roomId);
  data.append('uuid',`${roomId}_${clips.length}`);
  data.append('clip', file);

  dispatch(updatePendingQueue(true));

  ajax2utils({
    method: 'post',
    url: '/create/clip',
    data: data,
    onUploadProgress: e => {
      const { loaded, total } = e;
      const percent = parseInt(loaded/total*100)/100;
      dispatch(updateRoomClipProgress(key, percent));
    }
  })
    .then(e => dispatch(updateClipStatus(key, 'Done')))
    .catch(err => {
      console.error(err);
      dispatch(updateClipStatus(key, 'Failed'));
    })
    .then(() => dispatch(updatePendingQueue(false)));
}


// ------ attachment actions ------ //
const updateRoomAttachment = (id, key, attachment) => ({
  id,key, attachment,
  type:ActionTypes.UPDATEROOMATTACHMENT,
});

const updateAttachmentProgress = (key, percent) => ({
  key, percent,
  type: ActionTypes.UPDATEROOMATTACHMENTPROGRESS,
});

const updateAttachmentStatus = (key, status) => ({
  key, status,
  type: ActionTypes.UPDATEROOMATTACHMENTSTATUS
});

export const uploadRoomAttachment = (file, roomId) => (dispatch, getState) => {
  const data = new FormData();
  const attachments = getState().create.attachments.concat();
  const key = _.string(6, attachments);
  const data2Store = {
    filename: file.name,
    status:'Pending',
    percentage: 0,
  };

  dispatch(updateRoomAttachment(roomId, key, data2Store));

  data.append('roomId', roomId);
  data.append('uuid',`${roomId}_${attachments.length}`);
  data.append('attachment', file);

  dispatch(updatePendingQueue(true));

  ajax2utils({
    method: 'post',
    url: '/create/attach',
    data: data,
    onUploadProgress: e => {
      const { loaded, total } = e;
      const percent = parseInt(loaded/total*100)/100;
      dispatch(updateAttachmentProgress(key, percent));
    }
  })
    .then(e => dispatch(updateAttachmentStatus(key, 'Done')))
    .catch(err => {
      console.log(err);
      dispatch(updateAttachmentStatus(key, 'Failed'));
    })
    .then(() => dispatch(updatePendingQueue(false)));
}
//cancel File function for MultipleUpload component
export const cancelFile = (files) => ({
  type:ActionTypes.CANCELFILE,
  newData:files
})


// ---- pending queue actions ---- //
const updatePendingQueue = (status) => ({
  type: status?
    ActionTypes.PENDINGQUEUEPUSH: ActionTypes.PENDONGQUEUESHIFT,
});


// ---- stepper actions ---- //
export const nextStep = activeStep => ({
  type: ActionTypes.NEXTSTEP,
  activeStep: activeStep
});

export const lastStep = activeStep => ({
  type: ActionTypes.LASTSTEP,
  activeStep: activeStep
});
