import * as ActionTypes from '../redux/ActionTypes';


export default (state = [], action) => {
  const { type } = action;
  if (type === ActionTypes.TOGGLEROOM) {
    return {
      ...state,
      roomModalIsOpen: action.roomModalIsOpen
    };
  } else if (type === ActionTypes.TOGGLELOGIN) {
    return {
      ...state,
      loginModalIsOpen: action.loginModalIsOpen
    };
  } else if (action.type === ActionTypes.TOGGLESUBSCRIBE) {
    return {
      ...state,
      subscribeModalIsOpen: action.subscribeModalIsOpen
    };
  } else if (type === ActionTypes.OPENUSERDWOPDWON) {
    return {
      ...state,
      userDropdownIsOpen: action.userDropdownIsOpen,
    };
  } else if (type === ActionTypes.SCHEDULECOUNT) {
    return {
      ...state,
      scheduleCount: action.scheduleCount,
    };
  } else if (type === ActionTypes.SUCCESSCOUNT) {
    return {
      ...state,
      successCount: action.successCount++,
    };
  } else {
    return state;
  }
}