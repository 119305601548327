import * as ActionTypes from './type';


export const updateCoursenumber = (num) => ({
    type: ActionTypes.UPDATECOURSEBUMBER,
    number:num
})

export const updateCourseInfo = (title, brief) => ({
    type: ActionTypes.UPDATECOURSEINFO,
    title:title,
    brief:brief,
})
export const updateCourseCover = (cover) => ({
    type: ActionTypes.UPDATECOURSECOVER,
    cover:cover,
})

export const updateCourseDemo = (demo) => ({
    type: ActionTypes.UPDATECOURSEDEMO,
    demo:demo,
})
export const updateCourseAddCount = (count) => ({
    type: ActionTypes.UPDATECOURSEADDCOUNT,
    count:count,
})
export const nextStep = activeStep => ({
    type: ActionTypes.NEXTSTEP,
    activeStep: activeStep
});

export const lastStep = activeStep => ({
    type: ActionTypes.LASTSTEP,
    activeStep: activeStep
});
export const setStep = activeStep => ({
    type: ActionTypes.SETSTEP,
    activeStep: activeStep
});

export const initChapters = (initchapters,prechaptertitles,prekeys) =>({
    type: ActionTypes.INITCHAPTERS,
    initchapters:initchapters,
    prechaptertitles:prechaptertitles,
    prekeys:prekeys,
});
export const updateChapters = (chapters) =>({
    type: ActionTypes.UPDATECHAPTERS,
    chapters:chapters,
});
export const updateUploader = (organize,uploader) =>({
    type: ActionTypes.UPDATEUPLOADER,
    uploader:uploader,
    organize:organize
});