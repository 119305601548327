import React from 'react'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import _ from '../utils'


const Container = styled.div`
  width: 100%;
  justify-self: center;
  grid-area:${props => props.areaname};
`;


class IntroInput extends React.Component {

  state = { introVal: this.props.value || '' };
  introDebounced = _.debounce(this.props.getIntroVal || function () { }, 500);

  reset = () => {
    this.setState({
      introVal: ''
    })
  }
  preread = (Val) => {
    this.setState({
      introVal: Val
    })
  }
  updateIntroVal = e => {
    e.preventDefault();
    const value = e.target.value;
    if(value.length >1001){
      // window.alert('課程描述不應超過1000個字符');
      return false;
    }
    this.setState({ introVal: value });
    this.introDebounced(value);
  };
  render() {
    const { intro, rows, introId, value } = this.props;
    const { introVal } = this.state;
    return (
      <Container areaname={this.props.areaname}>
        <TextField
          id={introId}
          variant="outlined"
          fullWidth={true}
          value={introVal}
          onChange={this.updateIntroVal}
          multiline={true}
          margin='normal'
          rows={rows}
          // label={intro}
          placeholder={intro}
        />
      </Container>
    )
  }
}

export default IntroInput;
