import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
//material ui
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//redux
import { connect } from 'react-redux'
import { toggleRoom } from '../redux/Actions';
//config
import config from '../config'
//plguin
import axios from 'axios';
import qs from 'qs'
// 柵格佈局
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';


const Wrapper = styled.div`
`;

const inputStyle = {
  width: '100%',
  padding: '8px 10px',
  border: 'none',
  outline: 'none'
}
const spanStyle = {
  fontSize: '14px',
}

const mapStateToProps = (state) => {
  return {
    roomModalIsOpen: state.modals.roomModalIsOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal(state) {
      dispatch(toggleRoom(state));
    }
  }
}



class RoomModal extends React.Component {
  state = {
    prompt: false,
    room: '', // 房間號
    roomPassWord: '', // 房間密碼
  };

  removePrompt = () => {
    this.setState({
      prompt: false
    })
  };

  handleClose = () => {
    this.props.openModal(false)
    this.removePrompt();
  };

  submitRoomNumber = () => {
    const { room, roomPassWord } = this.state
    // let self = this;
    // let roomId = document.getElementById('roomId').value;
    if (!room) {
      window.alert(this.props.language.room_id_null);
      return;
    }
    axios.post(`${config.env.edu}room/getcourse/`, qs.stringify({ mode: `${encodeURIComponent(room)},${roomPassWord}` }))
      .then((res) => {
        if (res.data.content) {
          window.location.href = `/tutor/room/${res.data.id}`
        }else if(res.data.chapterid){
          window.location.href = `/tutor/room/${res.data.id}`
        } else {
          window.location.href = `/course/${res.data.courseid}`
        }
      })
      .catch((err) => {
        window.alert(err.response.data)
      })
  }
  enter = (e) => {
    if (e.keyCode === 13) {
      this.submitRoomNumber()
    }
  }
  render() {
    const {language} = this.props
    return (
      <Wrapper onKeyDown={this.enter}>
        <Dialog
          open={this.props.roomModalIsOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <div style={{ background: ' #3D67BC', padding: '10px 10px 16px 10px', minWidth: 500, fontSize:'18px' ,textAlign:'center'}}>
            {/* <img src={require('../assets/img/Nav__Logo.svg')} alt="logo" style={{ position: 'relative', marginRight: 20, top: '3px', width : '40%' }} /> */}
            <span style={{ lineHeight: 1, color: '#fff' }}>{language.enter_the_room}</span>
            <img src={require('../assets/img/close.png')} onClick={this.handleClose} alt="close" style={{ width: '22px', position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} />
          </div>
          <div style={{ padding: '50px 20px' }}>
            <Grid container spacing={24}>
              <Grid item xs={3}>
                <div style={{ padding: 6, textAlign: 'right' }}>
                  <span style={spanStyle}>{language.room_id}:</span>
                </div>
              </Grid>
              <Grid item xs={8}>
                <div style={{ border: '1px solid #ccc', borderRadius: '4px', padding: 2 }}>
                  <input style={inputStyle} placeholder={language.room_id} onChange={(e) => {
                    this.setState({ room: e.target.value })
                  }} />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={24} style={{ marginTop: 30 }}>
              <Grid item xs={3}>
                <div style={{ padding: 6, textAlign: 'right',overflow: 'hidden',textOverflow:'ellipsis',whiteSpace: 'nowrap' }}>
                  <span style={spanStyle}>{language.room_passworld}:</span>
                </div>
              </Grid>
              <Grid item xs={8}>
                <div style={{ border: '1px solid #ccc', borderRadius: '4px', padding: 2 }}>
                  <input type="text" style={inputStyle} placeholder={language.room_passworld} onChange={(e) => { this.setState({ roomPassWord: e.target.value }) }} css={` -webkit-text-security:disc;text-security:disc;`}/>
                </div>
              </Grid>
            </Grid>
          </div>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">{language.cancel}</Button>
            <Button onClick={this.submitRoomNumber} color="primary">{language.room_enter}</Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomModal);