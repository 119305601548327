import React from 'react'
import styled from 'styled-components'

import FileButton from './FileButton'
import _ from '../utils'


const Wrapper = styled.div`
  border-radius: 8px;
  border: 4px ${props => props.borderColor} dashed;
`;


class DragDropBrowse extends React.Component {
  timer = null;

  state = {
    color: '#3c5e8f80',
  }

  mouseenter = e => {
    this.setState({ color: '#3c5e8fe6' });
  }

  mouseleave = e => {
    this.setState({ color: '#3c5e8f80' });
  }

  dragover = e => {
    e.stopPropagation();
    e.preventDefault();
    if (this.state.color !== '#3c5e8fe6') {
      this.setState({ color: '#3c5e8fe6' });
    }
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  dragleave = e => {
    e.stopPropagation();
    e.preventDefault();
    if (!this.timer) {
      this.timer = setTimeout(
        () => this.setState({ color: '#3c5e8f80' }), 200);
    }
  }

  drop = e => {
    e.stopPropagation();
    e.preventDefault();
    let files = e.dataTransfer.files;
    this.uploadFiles(files);
  }

  change = e => {
    e.stopPropagation();
    e.preventDefault();
    let files = e.target.files;
    this.uploadFiles(files)
    e.target.value = "";
  }
  
  uploadFiles = files => {
    let { type } = this.props;
    const types = type && type.split('/');
    const target = [];
    const maxSize = this.props['max-size'] || 51200000;
    const maxLength = this.props['max-length'] === 0? 0: this.props['max-length'] || 10;

    if (types[1] === '*') {
      if (types[0] === '*') {
        type = null;
      } else {
        type = `${types[0]}/`;
      }
    }

    for (let i = 0; i < files.length; i++) {
      if ((!type || files[i].type.indexOf(type) > -1)) {
        if (files[i].size <= maxSize) {
          target.push(files[i]);
        } else {
          alert(`上傳的每個文件不應該超過 ${parseInt(maxSize/1024/1000)}mb`);
          target.length = 0;
          break;
        }
      }
    }

    if (target.length <= maxLength) {
      // for (let i = 0; i < target.length; i++) {
        this.props.upload(target);
      // }
    } else {
      alert("上傳的文件超過了規定的數量");
    }
  }

  render () {
    const { inputid, type, children } = this.props;
    return (
      <Wrapper
        borderColor={this.state.color}
        onMouseEnter={this.mouseenter}
        onMouseLeave={this.mouseleave}
        onDragOver={this.dragover}
        onDragLeave={this.dragleave}
        onDrop={this.drop}
      >
        <FileButton
          bid={inputid}
          accept={type}
          change={this.change}
          variant="text"
          size="large"
          fullWidth={true}
          color="primary"
          height="100%"
        >
          {children}
        </FileButton>
      </Wrapper>
    )
  }
}

export default DragDropBrowse;
