import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.p`
  line-height:26px;
  letter-spacing:1px;
  width:100%;
  height:100%;
  grid-area:${props=>props.areaname};
  font-size:1.5em;
  margin:0;
  padding:0;
  color:${props=>props.color || '#232A34'};
  word-break:break-all;
`;

class Content extends React.Component {
  render () {
    return(
      <Wrapper
        areaname={this.props.areaname}
        color={this.props.color}
        {...this.props}
      >
        {this.props.text}
      </Wrapper>
    )
  }
}

export default Content;
