import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
//material ui
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//redux
import { connect } from 'react-redux'
import { toggleSubscribe } from '../redux/Actions';
//config
import config from '../config'
//plguin
import axios from 'axios';


const Wrapper = styled.div`
`;

const mapStateToProps = (state) => {
  return {
    subscribeModalIsOpen: state.modals.subscribeModalIsOpen,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    openModal(state) {
      dispatch(toggleSubscribe(state));
    }
  }
}



class SubscribeModal extends React.Component {
  state = {
    prompt:false
  };
  handleChange = () => {
    let email = document.getElementById('subscribeEmail').value;
    if(this.verifyEmail(email)){
      console.log('Hi');
    }
    this.removePrompt();
  }
  verifyEmail = (email) => {
    let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return  re.test(String(email).toLowerCase());
  }
  removePrompt =()=>{
    this.setState({
      prompt:false
    })
  }
  handleClickOpen = () => {
    // this.props.openModal(true)
    this.setState({
      prompt:true
    })
  };

  handleClose = () => {
    this.props.openModal(false)
    this.removePrompt();
  };
  submitSubscribe = () => {
    let email = document.getElementById('subscribeEmail').value;
    if(this.verifyEmail(email)){
      let courseId = this.props.match.params.courseId;
      let self = this;
      axios.post(`${config.env.server}/subscribe`,{
        courseId,
        email,
      })
      .then((res) => {
        if(res.data === 'Subscribe Updated.'){
          window.alert('訂閱成功') ;
          self.handleClose();
        }
      })
      .catch((err) => {
        console.log(err)
      })
    }else{
      this.setState({
        prompt:true
      })
    }
  }
  enter=(e)=>{
    if(e.keyCode === 13){
      this.submitSubscribeNumber()
    }
  }
  render () {
    return(
      <Wrapper onKeyDown={this.enter}>
        <Dialog
          open={this.props.subscribeModalIsOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">輸入電子郵箱</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
             當課程上線，我們將會第一時間通知您。
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="subscribeEmail"
              label="電子郵箱"
              error={this.state.prompt}
              helperText={this.state.prompt&&'請輸入有效的郵箱地址'}
              fullWidth
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              取消
            </Button>
            <Button onClick={this.submitSubscribe} color="primary">
              訂閱
            </Button>
          </DialogActions>
        </Dialog>
      </Wrapper>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeModal);
