import React from 'react'
import Slider from "react-slick";
import styled from  'styled-components';
import '../assets/css/Slider.css'
import {Link} from 'react-router-dom'
//config
import config from '../config'

const Wrapper = styled.div`
  grid-area:${props=>props.areaname};
  height:30vw;
  @media screen and (max-width:767px){
    height:50vw;
  }
`;
const ImgWrapper = styled.div`
  @media screen and (max-width:1023px){
      height:200px;
      width:100%;
  }
`;
class Carousel extends React.Component {
  render () {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      arrows:false,
      autoplay:true,
    };

    return (
      <Wrapper areaname={this.props.areaname}>
        <Slider {...settings}>
          {this.props.imgs.map((x,y)=>(
            x.courseId
            ?
            <Link key={`Banner__${y}`} to={`${config.env.basename}/course/${x.number}`}>
              <ImgWrapper>
                <img
                  src={x.poster}
                  alt={`Banner__${y}`}
                  css={`
                    width:100%;
                    height:100%;
                    object-position:50% 100%;
                    object-fit:cover;
                    `}/>
              </ImgWrapper>
            </Link>
            :<a href={x.url} key={`Banner__${y}`}>
              <ImgWrapper>
                <img
                  src={x.poster}
                  alt={`Banner__${y}`}
                  css={`
                    width:100%;
                    height:100%;
                    object-position:50% 100%;
                    object-fit:cover;
                    `}/>
              </ImgWrapper>
             </a>
          ))}
        </Slider>
      </Wrapper>
    )
  }
}

export default Carousel;
