import React from 'react'
import styled from 'styled-components'
import FileButton from './FileButton'
import DelButton from './DelButton'
import PropTypes from 'prop-types'


//plugin axios
import axios from 'axios'
import config from '../config'

//redux
import {connect} from 'react-redux'
//redux-actions
import { createRoomId ,uploadRoomSlides} from '../actions/create'


// 345 x 180
const Container = styled.div`
  display: grid;
  border-radius: 8px;
  height: 100%;
  grid-area:${props=>props.areaname};
  background-color:#383838;
  border:2px solid #3D67BC;
  margin:5px;
`;

const Image = styled.img`
  min-width:345px;
  height:100%;
  object-fit: contain;
  background-color:#fff;
  place-self:center;
`;
const Content = styled.div`
  min-width:345px;
  color:#fff;
  margin:0;
  padding:0;
  place-self:center;
`;

const mapDispatchToProps = (dispatch)=>{
  return {
    initRoomId(callback){
      dispatch(createRoomId(callback))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    roomId:state.create.roomId,
    name:state.create.slides.name,
  }
}

const acceptPaser = (type) => {
  let result = '';
  if (typeof type === 'string') {
    if (type.indexOf('/') > -1) {
      result = type;
    } else {
      result = `${type}/*`;
    }
  } else {
    result = type.map(val=> val+'/*').join(',');
  }
  return result;
}


class RoomCoverUploadPreview extends React.Component {
  state = {
    file: null,
    preview: null,
    content:this.props.value||null,
    type:null
  };
  cancelUpload = () => {
    if(this.source){
      this.source.cancel("Operation canceled by the user.");
    }
  }
  change = (e) => {
    const file = e.target.files[0];
    let type = this.props.type;
    const types = type && type.split('/');
    const content = file.name;
    const reader = new FileReader();
    const _self = this;
    const maxSize = this.props['max-size'] || 2048000;

    if (types[1] === '*') {
      if (types[0] === '*') {
        type = null;
      } else {
        type = `${types[0]}/`;
      }
    }

    if ((!type || file.type.indexOf(type) > -1)) {
      if (file.size <= maxSize) {
        console.log(type);
        console.log(file.type);
        let fileInfo = {
          path: URL.createObjectURL(file),
          name: content,
          type: file.type,
        }

        reader.addEventListener('load', function cb() {
          _self.setState({
            file: file,
            preview: reader.result,
            type: file.type,
            content
          });
          reader.removeEventListener('load', cb);
        });
        reader.readAsDataURL(file);

        this.props.onChange(fileInfo, file);
      } else {
        alert(`上傳的文件不應該超過 ${parseInt(maxSize/1024/1000)}mb`);
      }
    }
  };
  cancelPreview = () => {
    document.getElementById(this.props.inputid).value = '';
    this.setState({
      preview: null,
      file: null,
      content:null,
      type:null,
    })
    this.cancelUpload();
  }
  render () {
    const { inputid , placeholder ,type} = this.props;
    const {  preview,content } = this.state;
    return(
      <Container areaname={this.props.areaname}>
        <div css={`
          width: 100%;
          height: 100%;
          display: ${content? 'none': 'block'};
        `}>
          <FileButton
            bid={inputid}
            accept={acceptPaser(type)}
            change={this.change}
            variant="contained"
            size="large"
            fullWidth={true}
            color="primary"
            height="100%"
          >
            {placeholder}
          </FileButton>
        </div>
        <div css={`
          position: relative;
          display: ${content? 'grid': 'none'};
          overflow: hidden;
        `}>
          {new RegExp(/image/).test(this.state.type)
            ?<Image src={preview} alt={`${inputid}_preview`}/>
            :<Content>
              <h1>{this.state.content}</h1>
              {this.props.name === null? <h4>上傳中...</h4> : <h4>上傳完成</h4>  }
             </Content>
          }
          <div css={`
            position: absolute;
            top: 5px;
            right: 5px;
            height: 50px;
            width: 50px;
          `}>
          <DelButton fn={this.cancelPreview}/>
          </div>
        </div>
      </Container>
    )
  }
}


RoomCoverUploadPreview.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
}

export default connect(mapStateToProps,mapDispatchToProps)(RoomCoverUploadPreview);
