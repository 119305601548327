import React from 'react'
import { connect } from 'react-redux'
import '../css/details.css';
import { Link } from 'react-router-dom'
import axios from 'axios';
import qs from 'qs';
import config from '../config'
import { toggleRoom, toggleLogin, toggleUserDropdown } from '../redux/Actions';

const regEmail = new RegExp("[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+$");
const regpass = new RegExp(/^(?=.*\d)(?=.*[A-Za-z]).{6,}$/);
const mapDispatchToProps = (dispatch) => ({
    openRoomModal(state) {
        dispatch(toggleRoom(state));
    },
    toggleLogin(state) {
        dispatch(toggleLogin(state));
    },
    openUserDropdown(state) {
        dispatch(toggleUserDropdown(state));
    },
});
const mapStateToProps = state => {
    const { profile } = state.user;
    return {
        loginModalIsOpen: state.modals.loginModalIsOpen,
        userDropdownIsOpen: state.modals.userDropdownIsOpen,
        account: profile && profile.account,
        name: profile && profile.name
    };
};

class MobileHome extends React.Component {
    // const [completed, setCompleted] = useState(0)
    constructor(props) {
        super(props)
        this.state = {
            isShow: true,
            email: '',   // 郵箱
            passWord: '',    // 密碼
            clickShow: true, // 點擊後自毀
        }
    }

    componentDidMount() {
        document.getElementsByClassName('mobile_home')[0].parentNode.children[0].style.display = 'none'
        document.getElementsByClassName('mobile_home')[0].parentNode.children[2].style.display = 'none'
        let clientHeight = document.body.clientHeight
        document.getElementsByClassName('sc_content')[0].style.minHeight = (clientHeight - 85) + 'px' 
        if (this.QueryString('email') && this.QueryString('key')) {
            this.setState({
                isShow: false
            })
        }
    }

    // 截取某個字符串
    QueryString = (item) => {
        var sValue = window.location.search.match(new RegExp("[\?\&]" + item + "=([^\&]*)(\&?)", "i"));
        return sValue ? sValue[1] : sValue;
    }

    submit = () => {

    }

    render() {
        return (
            <div className="mobile_home" >
                {/* <!-- 頭部 --> */}
                <div className="sc-head">
                    <span>作品上傳</span>
                </div>
                {/* <!-- 内容 --> */}
                <div className="sc_content">
                    <div className="sc_list">
                        <div className="sc_imgs"><img src={require('../assets/images/touxiang.png')} alt="" /></div>
                        <div className="sc_infos">林追追LING </div>
                    </div>
                    <div className="sc_list">
                        <div className="sc_imgs"><img src={require('../assets/images/touxiang.png')} alt="" /></div>
                        <div className="sc_infos">
                            蔡维泽LING
                            <Link to="/mobileView">
                                <button>查看作品</button>
                            </Link>
                        </div>

                    </div>
                    <div className="sc_list">
                        <div className="sc_imgs"><img src={require('../assets/images/touxiang.png')} alt="" /></div>
                        <div className="sc_infos">蔡啦啦LING</div>
                    </div>
                </div>
                <Link to="/mobileSub">
                    <div className="sc_bottom">
                        <button>點擊上傳作品</button>
                    </div>
                </Link>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileHome);;
