import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
//container
import CourseIntro from './container/CourseIntro'
import ChapterSelect from './container/ChapterSelect'
import Homepage from './container/Homepage';
import Login from './container/login';
import CloudLogin from './container/logins';
import LoginRicoh from './container/login_ricoh';
import LoginEn from './container/login_en';
import LoginAi from './container/login_ai';
import Profile from './container/profile';
import Admin from './container/admin';
import CourseUpload from './container/CourseUpload'
import RoomCreate from './container/RoomCreate'
import UserInfo from './container/UserInfo'
import NewCourseUpload from './container/NewCourseUpload'
import MobileHome from './container/mobileHome'
import MobileView from './container/mobileView'
import MobileSub from './container/mobileSub'
import RoomResult from './components/RoomResult'
//components
import Nav from './components/Nav'
import Footer from './components/Footer'
//css
import styled from 'styled-components'
//component
import SuggestResolution from './components/SuggestResolution';
// provider
import { Provider } from 'react-redux';
// store
import store from './redux/Store';
//config
import config from './config';
// 忘记密码
import PassWord from './container/passWord'
// 創建房間
import Room from './container/room'
// 创建录播课程
import CourseRecordUpload from './container/CourseRecordUpload'

import dataLang from './data.json'



const Wrapper = styled.div`
  overflow:hidden;
  // position:relative;
  display:grid;
  grid-template-columns: repeat(1, calc(100% / 1));
  grid-template-rows: 67.5px 1fr 67.5px;
  min-height: 100vh;
  grid-template-areas:
  'nav'
  'body'
  'footer';
  font-size:12px;
  @media screen and (max-width:1024px){
    font-size:10px;
  }
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
      language: '',
      userid: ''
    }
  }
  componentDidMount() {
    // const script = document.createElement('script');
    // script.type = "text/javascript";
    // script.src = "//script.crazyegg.com/pages/scripts/0082/8235.js";
    // script.async = "async";
    // document.body.appendChild(script);
    // window.localStorage['identity'] = undefined
    if (window.localStorage.language && JSON.parse(window.localStorage.language) === 1) {
      this.setState({
        language: dataLang.language.english
      })
    } else {
      window.localStorage['language'] = 2
      this.setState({
        language: dataLang.language.chinese
      })
    }
  }

  getLanguage = (v) => {
    if (v === "English") {
      this.setState({
        language: dataLang.language.english
      })
    } else {
      this.setState({
        language: dataLang.language.chinese
      })
    }
  }

  getUserId = (userid) => {
    console.log(userid, '1')
    this.setState({
      userid
    })
  }

  render() {
    const { language, userid } = this.state
    return (
      <Provider store={store}>
        <Router basename='/course' >
          {/*<ScrollToTop >*/}
          <div className="App">
            <Wrapper>
              <Route path='/' render={({ match, location, history }) => (
                <Nav language={language} areaname='nav' match={match} location={location} history={history} getLanguage={v => this.getLanguage(v)}
                  getUserId={u => this.getUserId(u)} />
              )} />
              <Switch>
                <Route path='/' exact render={({ match, location }) => (
                  <Homepage areaname='body' userid={userid} match={match} location={location} language={language} />
                )} />
                <Route path='/login' exact render={({ match, location }) => (
                  <Login areaname='login' match={match} location={location} language={language} getUserId={u => this.getUserId(u)} />
                )} />
                <Route path='/LoginIframe' exact render={({ match, location }) => (
                  <CloudLogin areaname='LoginIframe' match={match} location={location} language={language} getUserId={u => this.getUserId(u)} />
                )} />
                <Route path='/LoginRicoh' exact render={({ match, location }) => (
                  <LoginRicoh areaname='LoginRicoh' match={match} location={location} language={language} getUserId={u => this.getUserId(u)} />
                )} />
                <Route path='/Login_en' exact render={({ match, location }) => (
                  <LoginEn areaname='LoginIframe' match={match} location={location} language={language} getUserId={u => this.getUserId(u)} />
                )} />
                <Route path='/Login_ai' exact render={({ match, location }) => (
                  <LoginAi areaname='LoginAi' match={match} location={location} language={language} getUserId={u => this.getUserId(u)} />
                )} />
                <Route path='/password' exact render={({ match, location, history }) => (
                  <PassWord areaname='password' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/room' exact render={({ match, location, history }) => (
                  <Room areaname='room' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/profile' exact render={({ match, location, history }) => (
                  <Profile areaname='profile' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/admin' exact render={({ match, location, history }) => (
                  <Admin areaname='admin' match={match} location={location} history={history} language={language} />
                )} />
                <Route
                  path={'/upload'}
                  render={({ match, history }) => (<CourseUpload areaname='body' match={match} history={history} language={language} />)}
                />
                <Route
                  path={'/roomResult'}
                  render={({ match, history }) => (<RoomResult areaname='body' match={match} history={history} language={language} />)}
                />

                {/* <Route
                  path={'/upload'}
                  render={({ match, history }) => (<CourseUpload areaname='body' match={match} history={history} />)}
                /> */}
                {/* <Route path='/mobileHome' exact render={({ match, location, history }) => (
                  <MobileHome areaname='admin' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/mobileView' exact render={({ match, location, history }) => (
                  <MobileView areaname='admin' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/mobileSub' exact render={({ match, location, history }) => (
                  <MobileSub areaname='admin' match={match} location={location} history={history} language={language} />
                )} /> */}
                <Route
                  path={'/newupload'}
                  render={({ match }) => (<NewCourseUpload areaname='body' match={match} />)}
                />
                <Route
                  path={'/my'}
                  exact
                  render={({ match }) => (<UserInfo areaname='body' />)}
                />
                <Route
                  path={'/create'}
                  render={({ match }) => (<RoomCreate areaname='body' />)}
                />
                <Route
                  path={`${config.env.basename}/course/:courseId/`}
                  exact
                  render={({ match, location }) => (
                    <CourseIntro
                      areaname='body'
                      match={match}
                      location={location}
                    />
                  )} />
                <Route
                  path={`${config.env.basename}/:courseId/`}
                  exact
                  render={({ match, location, history }) => (
                    <CourseIntro
                      areaname='body'
                      match={match}
                      location={location}
                      language={language}
                      history={history}
                    />
                  )} />
                <Route
                  path={'/recordUpload'}
                  render={({ match, history }) => (<CourseRecordUpload areaname='body' match={match} history={history} language={language} />)}
                />

                <Route
                  path={`${config.env.basename}/:courseId/chapter`}
                  render={({ match, location, history }) => (
                    <ChapterSelect
                      areaname='body'
                      match={match}
                      language={language}
                      location={location}
                      history={history}
                    />
                  )} />
                <Route
                  path={`/course/:courseId/`}
                  exact
                  render={({ match }) => (<Redirect to={`${config.env.basename}/${match.params.courseId}`} />)}
                />
                <Route render={() => (<Redirect to='/course/' />)}></Route>
              </Switch>
              <Footer areaname='footer' />
              {/*  */}
              {/*<SuggestResolution/>*/}
            </Wrapper>
          </div>
          {/* <Wrapper></Wrapper> */}
          {/*</ScrollToTop>*/}
        </Router>
      </Provider>
    );
  }
}

export default App;
