import React from 'react'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import _ from '../utils'


const Container = styled.div`
  // float: left;
  width: 100%;
  justify-self: center;
  grid-area:${props => props.areaname};
`;


class TitleInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = { titleVal: '' };
  }
  preread = (Val) => {
    this.setState({
      titleVal: Val
    })
  }
  reset = () => {
    this.setState({
      titleVal: ''
    })
  }
  titleDebounced = _.debounce(this.props.getTitleVal || function () { }, 500);
  updateTitleVal = e => {
    e.preventDefault();
    const value = e.target.value;
    // if (value.length > 21) {
    //   // window.alert('課程名稱不應超過20個字符');
    //   return false;
    // }
    this.setState({ titleVal: value });
    this.titleDebounced(value);
  };

  render() {
    const { title } = this.props;
    const { titleVal } = this.state;
    return (
      <Container areaname={this.props.areaname}>
        <TextField
          fullWidth={true}
          value={titleVal}
          onChange={this.updateTitleVal}
          // label={title}
          placeholder={title}
          variant='outlined'
          margin="dense"
        />
      </Container>
    )
  }
}

export default TitleInput;
