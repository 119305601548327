export const INITCOURSEID = 'initCourseId';
export const INITCHAPTERINFO = 'initChapterInfo';

export const UPDATECHAPTERNUM = 'updateChapterNum';
export const UPDATECHAPTER = 'updateChapter';
export const UPDATECHAPTERCOVER = 'updateChapterCover';
export const UPDATECHAPTERINTRO = 'updateChapterIntro';

export const LASTSTEP = 'lastStep';
export const NEXTSTEP = 'nextStep';


export const UPDATECOURSEBUMBER = 'updateCoursenumber';
export const UPDATEATTACHMENTPROGRESS = 'updateAttachmentProgress';
export const UPDATEATTACHMENTSTATUS = 'updateAttachmentStatus';
export const UPDATECOURSEINFO= 'updateCourseInfo';
export const UPDATECOURSECOVER = 'updateCourseCover';
export const UPDATECOURSEADDCOUNT = 'updateCourseAddCount';
export const UPDATECOURSEDEMO = 'updateCourseDemo';
export const UPDATECHAPTERCLIPS = 'updateChapterClips';
export const UPDATECLIPPROGRESS = 'updateClipProgress';
export const UPDATECLIPSTATUS = 'updateClipStatus';
export const UPDATECHAPTERSLIDES = 'updateChapterSlides';
export const UPDATECHAPTERATTACHMENTS = 'updateChapterAttachments';
