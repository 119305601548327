import React from 'react'
import styled from 'styled-components'


const Wrapper = styled.h3`
  // text-align: left;
  margin:0;
  padding:0;
  grid-area:${props => props.areaname};
  width:${props => props.inline?'50%':'100%'};
  height:100%;
  font-size:2em;
  //color:${props => props.color || '#5F6F85'};
  color:black;
  background-color:${props => props.backgroundColor};
  display:block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

class Subtitle extends React.Component {
  render() {
    const { areaname, backgroundColor, color, inline } = this.props;
    return (
      <Wrapper
        areaname={areaname}
        backgroundColor={backgroundColor}
        color={color}
        inline={inline}
        {...this.props}
        title={this.props.name}
      >
        {this.props.name}
      </Wrapper>
    )
  }
}

export default Subtitle;
