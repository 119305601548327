import React from 'react'
import '../assets/css/uploadPreview.css';
import icon from '../assets/img/icon.png'
import styled from 'styled-components'
import FileButton from './FileButton'
import DelButton from './DelButton'
import Overviewitem from './OverviewItem'
import PropTypes from 'prop-types'
import _ from '../utils'
//plugin axios
import axios from 'axios'
import config from '../config'

//redux
import { connect } from 'react-redux'
//redux-actions
import { initChapters, updateCourseAddCount } from '../actions/newupload/index'
import { scheduleCounts, successCounts } from '../redux/Actions';

const Chapter = styled.div.attrs({
  id: `chapter_`,
})`
text-align: left;
background-color: #f2f2f2;
width: 100%;
height: 69px;
padding: 0px 15px 0px 15px;
border-radius: 8px;
overflow: hidden;
font-size: 16px;
`;

const mapStateToProps = (state) => {
  return {
    cover: state.newupload.course.cover,
    demo: state.newupload.course.demo,
    count: state.newupload.course.count,
    scheduleCount: state.modals.scheduleCount,
    successCount: state.modals.successCount
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    initChapters(initchapters, prechaptertitles, prekeys, length) {
      dispatch(initChapters(initchapters, prechaptertitles, prekeys, length));
    },
    updateCourseAddCount(count) {
      dispatch(updateCourseAddCount(count));
    },
    scheduleCounts(state) {
      dispatch(scheduleCounts(state));
    },
    successCounts(state) {
      dispatch(successCounts(state));
    },
  }
}

class CoverUploadPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: this.props.cover || []
    };
    this.coverDebounced(this.state.file)
    // var credentials = {
    //   accessKeyId: 'AKIATLPEDU37QV5CHLMH',
    //   secretAccessKey: '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'
    // };  //秘钥形式的登录上傳
    // window.AWS.config.update(credentials);
    // window.AWS.config.region = 'cn-northwest-1';   //设置区域
  }
  preread = (Val) => {
    this.setState({
      file: Val
    })
  }
  reset = () => {
    this.setState({
      file: []
    })
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.type = "text/javascript";
    script.src = "https://sdk.amazonaws.com/js/aws-sdk-2.235.1.min.js";
    script.async = "async";
    script.onload = function () {
      var credentials = {
        accessKeyId: 'AKIATLPEDU37QV5CHLMH',
        secretAccessKey: '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'
      };  //秘钥形式的登录上傳
      window.AWS.config.update(credentials);
      window.AWS.config.region = 'cn-northwest-1';   //设置区域
    }
    document.body.appendChild(script);
    this.setState({
      file: this.props.cover
    })
    this.coverDebounced(this.props.cover)
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.cover !== nextProps.cover) {
      this.setState({
        file: nextProps.cover
      })
    }
  }
  add = function (e) {
    var el = e.currentTarget
    el.getElementsByTagName("input")[0].click();
  }
  upload = function (e) {
    var input = e.currentTarget;
    if (input.files.length !== 0) {
      // 判断上傳文件大小
      // if (input.files[0].size / 1024 / 1024 > 10) {
      //   window.alert(this.props.language.upload_img_10)
      //   return false;
      // }
      let obj = {
        click: function () { },
        title: input.files[0].name,
        width: 0,
        url: "",
        style: { backgroundSize: '242%', backgroundPosition: '-105px -76px' } // 
      }
      this.state.file.push(obj)
      this.setState({
        file: this.state.file
      })
      let count = this.props.count
      count++;
      this.props.updateCourseAddCount(count)
      // 开始上傳文件数+1
      let scheduleCount = this.props.scheduleCount
      this.props.scheduleCounts(++scheduleCount)
      // create bucket instance
      var bucket = new window.AWS.S3({ params: { Bucket: 'ccrb' } });  //选择桶
      var file = input.files[0];
      var _this = this;

      if (file) {
        var _uploadid = (window.localStorage['uploadid'] || "") + "/";
        var params = { Key: _uploadid + file.name, ContentType: file.type, Body: file, 'Access-Control-Allow-Credentials': '*', 'ACL': 'public-read' }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = { partSize: 2048 * 1024 * 1024, queueSize: 2, leavePartsOnError: true };
        obj.bucket = bucket;
        obj.params = params;
        bucket.upload(params, options).
          on('httpUploadProgress', function (evt) {
            obj.width = parseInt((evt.loaded * 80) / evt.total);
            _this.setState({
              file: _this.state.file
            })
          }).send(function (err, data) {
            obj.width = 100;
            if (err) {
              obj.title = _this.props.language.upload_fail
              obj.style = { backgroundSize: `253%`, backgroundPosition: '-78px -79px', display: "block" };
              _this.setState({
                file: _this.state.file
              })
              _this.coverDebounced(_this.state.file);
            } else if(obj.delete !== true) {
              let counts = _this.props.count
              counts--;
              _this.props.updateCourseAddCount(counts)
              obj.click = function () { window.open(data.Location); };
              obj.style = { backgroundPosition: '-5px -131px', display: "block" };
              obj.title = file.name;
              obj.url = data.Location;
              _this.setState({
                file: _this.state.file
              })
              _this.coverDebounced(_this.state.file);
            }
          });

        input.value = "";
      }
    }
  }
  move = (e, j) => {
    var el = e.currentTarget;
    el.style.zIndex = 999;
    var _pel = document.getElementById("chapter_");
    var _x, _y, _l, _t, _drag;
    var _event = e;  //var _event = event || window.event;这句话就是定义了一個变量来获取事件对象，因为不同的浏览器获取事件对象的方法有点不太一样，IE下是window.event，标准下是event，为了兼容所以写了event || window.event.
    _drag = el; //赋值被拖拽的对象元素
    _x = _event.clientX; //记录元素的left值距离屏幕的差距
    _y = _event.clientY; //记录元素的top值距离屏幕的差距
    _l = _drag.offsetLeft; //元素的左偏移
    _t = _drag.offsetTop; //元素的右偏移
    var self = this;
    document.body.onmousemove = function (event) {
      var _event = event || window.event, //var _event = event || window.event;这句话就是定义了一個变量来获取事件对象，因为不同的浏览器获取事件对象的方法有点不太一样，IE下是window.event，标准下是event，为了兼容所以写了event || window.event.
        _newx = _event.clientX - _x,
        _newy = _event.clientY - _y,
        _left = _newx + _l, // 移动后鼠标的水平距离减去元素未移动时的水平距离，等到元素水平移动的距离。
        _top = _newy + _t; // 移动后鼠标的垂直距离减去元素未移动时的垂直距离，等到元素垂直移动的距离。 
      // el.style.left = _left + 'px'; //改变被拖拽的对象元素的水平位置
      el.style.top = _top + 'px'; //变被拖拽的对象元素的垂直位置
    }
    document.body.onmouseup = function (event) {
      var _child = _pel.children,
        _event = event || window.event,
        _newx = _event.clientX - _x,
        _newy = _event.clientY - _y;
      var _i = Math.ceil(_newy / 60);
      for (var j = 0; j < _child.length - 1; j++) {
        if (_child[j] === el.parentNode) {
          _i += j;
          break;
        }
      }
      _i = (_i >= (_child.length - 1)) ? _child.length - 1 : _i;
      // _pel.insertBefore(el.parentNode, _child[_i]);
      el.style.zIndex = 1;
      el.style.top = 0;
      document.body.onmousemove = null;
      document.body.onmouseup = null;
      let list = self.state.file;
      let temp = list.splice(j, 1)[0];
      list.splice(_i > j ? _i - 1 : _i, 0, temp);
      self.state.file = list;
      self.setState({
        file: list
      })
      self.coverDebounced(list)
    }
  }
  del = (e, j, obj) => {
    let { file } = this.state
    let list = file
    if (this.props.count !== 0 && (obj && obj.delete)) {
      let count = this.props.count
      count--;
      this.props.updateCourseAddCount(count)
    }
    if (obj && obj.bucket) {
      obj.delete = true;
      obj.bucket.deleteObject(obj.params, function () { });
    }
    list.splice(j, 1)
    this.setState({
      file: list
    })
    this.coverDebounced(list)
  }
  onMouseDown = (e) => {
    e.stopPropagation()
  }
  coverDebounced = _.debounce(this.props.getCoverVal || function () { }, 500);
  render() {
    let { file } = this.state;
    let ItemList = () => {
      let list = []
      for (let j = 0; j < file.length; j++) {
        list.push(
          <React.Fragment key={j}>
            <div className="chapter_upload" onClick={(e) => file[j] && file[j].url ? window.open(file[j].url) : function () { }}>
              <div className="chapter_upload_move" onMouseDown={(e) => this.move(e, j)}>
                <div className="chapter_upload_t" style={{ width: file[j].width + '%' }}></div>
                <div className="chapter_upload_o">
                  <div className="chapter_upload_l">
                    <div className="chapter_upload_l_i">
                    </div>
                  </div>
                  <div className="chapter_upload_ic">
                    <div className={file[j].style.backgroundPosition !== "-5px -131px" ? "chapter_upload_ic_l1" : "chapter_upload_ic_l"}>
                      <div style={file[j].style.backgroundPosition !== "-5px -131px" ? { backgroundPosition: '0 0' } : file[j].style}>
                      </div>
                    </div>
                    <div className="chapter_upload_ic_r" onMouseDown={(e) => this.onMouseDown(e)} onClick={(e) => this.del(e, j, file[j])}>
                      <div>
                      </div>
                    </div>
                  </div>
                  <div className="chapter_upload_n">
                    {file[j].title}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }
      return list;
    }
    return (
      <Chapter className='chapter_'>
        {ItemList()}
        <div className="chapter_add" onClick={(e) => this.add(e)}>
          <div className="chapter_add_l">+</div>
          <div className="chapter_add_r">
            {this.props.language.add_files}
            <span style={{ fontSize: 12, color: '#ccc' }}>({this.props.language.img_10})</span>
          </div>
          <input type="file"
            accept="image/png,image/gif,image/jpeg,"
            className="chapter_add_input"
            onChange={(e) => this.upload(e)} />
        </div>
      </Chapter>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverUploadPreview);
