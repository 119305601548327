import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import '../css/login.css';
import { RGCaptcha, reset } from 'react-geetest-captcha';
import axios from 'axios';
import qs from 'qs';
import config from '../config'
import { toggleRoom, toggleLogin, toggleUserDropdown } from '../redux/Actions';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { Popover, CircularProgress, Typography } from '@material-ui/core';
const CAPTCHA_NAME = 'demoCaptcha';
const mapDispatchToProps = (dispatch) => ({
  openRoomModal(state) {
    dispatch(toggleRoom(state));
  },
  toggleLogin(state) {
    dispatch(toggleLogin(state));
  },
  openUserDropdown(state) {
    dispatch(toggleUserDropdown(state));
  },
});
const mapStateToProps = state => {
  const { profile } = state.user;
  return {
    loginModalIsOpen: state.modals.loginModalIsOpen,
    account: profile && profile.account,
    name: profile && profile.name
  };
};
const regEmail = new RegExp("^[A-Za-z0-9_.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$");
const regpass = new RegExp(/^(?=.*\d)(?=.*[A-Za-z]).{6,}$/);
// const useStyles = makeStyles({
//   progress: {
//     margin: theme.spacing(2),
//   },
// });

@withRouter
class login extends React.Component {
  // const [completed, setCompleted] = useState(0)
  constructor(props) {
    super(props)
    this.state = {
      login: true,
      schoolShow: false,  // 學校列表顯示
      list: [
      ],
      schoolName: '',   // 學校名稱
      schoolId: '',    // 選中的學校id
      value: '',  // 學校
      identityValue: 0,   // 選擇的身份
      identityShow: false,   // 身份選擇下拉列表
      userEmail: '',     // 用戶登錄郵箱
      userPassWord: '',    // 用戶登錄密碼
      registereEmail: '',    // 用戶註冊郵箱
      registerePassWord: '',    // 用戶註冊密碼
      progress: 0,         // 進度條
      title: '',
      progressShow: false,
      geetest_challenge: '',
      geetest_validate: '',
      geetest_seccode: ''
    }
  }

  componentDidMount() {
    this.getSchoolList('')
    var Height = document.documentElement.clientHeight
    var font = document.getElementsByClassName('login')[0];
    font.style.height = (Height - 150) + 'px'
    function blue() {
      // font.style.color = 'blue';
    }
    font.onclick = function () {
      blue();
    }
    //this.geetestRegister();
  }

  geetestRegister() {
    reset(CAPTCHA_NAME);
  }

  geetestValidate(isSuccess) {
    this.setState({
      geetest_challenge: isSuccess.geetest_challenge,
      geetest_validate: isSuccess.geetest_validate,
      geetest_seccode: isSuccess.geetest_seccode
    })
  }

  geetest = () => {
    return axios.get(`${config.env.api}geetest/pc-geetest/register`)
      .then((res) => {
        console.log(res.data)
        var captcha = (res && res.data) || {};
        return captcha;
      })
      .catch((err) => {
        console.log(err);
      })
  }

  // 獲取學校列表
  getSchoolList = e => {
    axios.post(`${config.env.edu}admin/selectorganize`, qs.stringify({ mode: e })).then(res => {
      this.setState({
        list: res.data[0]
      })
    })
  }

  progress = (n) => {
    let num = this.state.progress + n;
    num = num > 100 ? 0 : num
    this.setState({
      progress: num
    })
  }

  // 用戶登錄郵箱驗證
  loginEmail = e => {
    // 清楚首尾空格
    let str = e.target.value.replace(/(^\s*)|(\s*$)/g, "")
    if (!regEmail.test(str)) {
      // console.log(window.U);
      if(window.U.Alert!==undefined)
      window.U.Alert(this.props.language.email_regular)
    }
    // else {
    //   this.setState({
    //     userEmail: e.target.value
    //   })
    // }
  }

  // 用戶登錄密碼 
  loginPassWord = e => {
    this.setState({
      userPassWord: e.target.value
    })
  }

  // 點擊獲取學校列表
  schoolList = e => {
    this.setState({
      schoolShow: true,
    })
  }

  // 根據輸入的值動態獲取學校列表
  changeList = e => {
    // 根據輸入的值動態獲取學校列表
    this.getSchoolList(e.target.value)
    this.setState({
      value: e.target.value,
      schoolName: '',
      schoolShow: true,
      schoolId: ''
    })
  }

  // 點擊學校列表，獲取學校名稱
  clickList = (e) => {
    this.setState({
      value: e.name,
      schoolShow: false,
      schoolId: e.id
    })
  }

  // 點擊彈出身份列表
  identityShow = () => {
    const { identityShow } = this.state
    this.setState({
      identityShow: !identityShow
    })
  }

  // 點擊顯示登陸還是註冊
  loginShow = () => {
    const { login } = this.state
    this.setState({
      login: !login,
      geetest_challenge: '',
      geetest_validate: '',
      geetest_seccode: ''
    });
    this.geetestRegister()
  }

  // 註冊
  registered = () => {
    const { language } = this.props
    // if (this.state.geetest_challenge && this.state.geetest_validate && this.state.geetest_seccode) {
      const { schoolId, registereEmail, registerePassWord, identityValue, value, progress } = this.state
      let school = schoolId
      let registereEmails = registereEmail.replace(/(^\s*)|(\s*$)/g, "")
      if (!regEmail.test(registereEmails)) {
        window.U.Alert(language.email_regular);
        return;
      }
      if (registerePassWord.length<6) {
        window.U.Alert(language.password_regular);
        return;
      }
      if (!school) {
        school = value
      }
      this.setState({
        progressShow: true
      })
      let aa = setInterval(() => {
        this.progress(10)
      }, 100);
      let params = {
        username: registereEmails,
        password: registerePassWord,
        googleId: '',
        identity: identityValue,
        college: school,
        geetest_challenge: this.state.geetest_challenge,
        geetest_validate: this.state.geetest_validate,
        geetest_seccode: this.state.geetest_seccode
      }
      var _that = this;
      axios.post(`${config.env.server}user`, qs.stringify(params)).then(res => {
        _that.geetestRegister();
        _that.setState({
          progressShow: false,
          progress: 100
        })
        clearInterval(aa)
        if (res.data[0][0].type === 1) {
          window.U.Alert(`<p>${language.registered_successful}</p><br/><img src='/img/loading.gif' />`, 1000);
          setTimeout(() => {
            _that.setState({
              login: true,
              progress: 0
            })
          }, 1000);
        } else if (res.data[0][0].type === 0) {
          window.U.Alert(language.registered_fail1);
        } else {
          window.U.Alert(language.registered_fail);
        }
      }).catch(err => {
        _that.geetestRegister();
        _that.setState({
          progressShow: false
        }, () => {
          clearInterval(aa);
          if (err && err.response.data) {
            window.U.Alert(err.response.data);
          } else {
            window.U.Alert(language.registered_fail);
          }
        })
      })
    // }
    // else {
    //   window.U.Alert(`<p>${language.click_validation}</p>`, 1000);
    // }
  }

  // 登錄
  login = () => {
    const { language } = this.props
    // if (this.state.geetest_challenge && this.state.geetest_validate && this.state.geetest_seccode) {
      // /user
      const { userEmail, userPassWord } = this.state
      let str = userEmail.replace(/(^\s*)|(\s*$)/g, "")
      if (!regEmail.test(str)) {
        window.U.Alert(language.email_regular);
        return;
      }
      if (userPassWord.length<6) {
        window.U.Alert(language.password_regular);
        return;
      }
      this.setState({
        progressShow: true
      })
      let aa = setInterval(() => {
        this.progress(10)
      }, 100)
      let params = {
        geetest_challenge: this.state.geetest_challenge,
        geetest_validate: this.state.geetest_validate,
        geetest_seccode: this.state.geetest_seccode,
        loginUsername: str,
        loginPassword: userPassWord
      }
      axios.defaults.withCredentials = true;
      var _that = this;
      axios.post(`${config.env.server}user`, qs.stringify(params)).then(res => {
        _that.geetestRegister();
        if (res.data[0][0].active === 1) {
          _that.setState({
            progressShow: false,
            progress: 100
          }, () => {
            window.U.Alert(`<p>${language.login_successful}</p><br/><img src='/img/loading.gif' />`, 1000);
          })
          setTimeout(() => {
            window.localStorage['identity'] = JSON.stringify(res.data[0][0].identity)
            _that.props.toggleLogin(true)
            _that.props.history.push('/')
            _that.props.getUserId(res.data[0][0].userid)
            clearInterval(aa)
          }, 1000)
        } else {
          window.U.Alert(language.login_fail);
        }
      }).catch(err => {
        _that.geetestRegister();
        _that.setState({
          progressShow: false
        }, () => {
          if (err && err.response.data) {
            window.U.Alert(err.response.data);
          } else {
            window.U.Alert(language.login_fail);
          }
        })
      })
    // }
    // else {
    //   window.U.Alert(`<p>${language.click_validation}</p>`, 1000);
    // }
  }

  // 註冊郵箱驗證
  registereEmail = e => {
    let str = e.target.value.replace(/(^\s*)|(\s*$)/g, "")
    if (!regEmail.test(str)) {
      window.U.Alert(this.props.language.email_regular);
    }
    // else {
    //   this.setState({
    //     registereEmail: e.target.value
    //   })
    // }
  }

  enter = (e) => {
    if (e.keyCode === 13) {
      this.login()
    }
  }

  // 註冊密碼驗證
  registerePassWord = e => {
    // !regpass.test(e.target.value)
    if (e.target.value.length < 6) {
      window.U.Alert(this.props.language.password_regular);
    }
    // else {
    //   this.setState({
    //     registerePassWord: e.target.value
    //   })
    // }
  }

  render() {
    const { login, schoolShow, list, schoolName, value, title, progressShow, userEmail, progress, registerePassWord, registereEmail, userPassWord } = this.state
    const { language } = this.props
    return (
      <div className="login" onClick={(e) => {
        this.setState({
          schoolShow: false,
          identityShow: false
        })
      }}>
        <div className="contain" onKeyDown={this.enter} style={{ position: "absolute", top: "50%", marginTop: (login ? -218 : -232) + "px", left: 'calc(50% - 221px)' }}>
          {login ?
            <div style={{ padding: '0 20px', marginTop: '25px' }}>
              <div className="userLogin">
                <img src={require("../assets/img/email.png")} />
                <input placeholder={language.user_email} onBlur={this.loginEmail} value={userEmail} onChange={e => {
                  this.setState({
                    userEmail: e.target.value
                  })
                }} />
              </div>
              <div className="userLogin">
                <img src={require("../assets/img/password.png")} />
                <input placeholder={language.user_passworld} type="password" onChange={this.loginPassWord} value={userPassWord} />
              </div>
              <div>
                <Link to='/password'><p>{language.forget_passworld}</p></Link>
              </div>
            </div>
            :
            <div style={{ padding: '0 20px', marginTop: '25px' }}>
              <div className="userLogin">
                <img src={require("../assets/img/email.png")} />
                <input placeholder={language.electronic_email} onBlur={this.registereEmail} onChange={e => {
                  this.setState({
                    registereEmail: e.target.value
                  })
                }} value={registereEmail} />
              </div>
              <div className="userLogin">
                <img src={require("../assets/img/password.png")} />
                <input type="password" placeholder={language.user_passworld_registered} onBlur={this.registerePassWord} onChange={e => {
                  this.setState({
                    registerePassWord: e.target.value
                  })
                }} value={registerePassWord} />
              </div>
              <div className="userLogin" onClick={(e) => {
                e.stopPropagation()
              }}>
                <img src={require("../assets/img/schoo.png")} style={{ width: '23px', paddingRight: 3 }} />
                <input placeholder={language.user_school}
                  onClick={this.schoolList}
                  onChange={this.changeList}
                  onFocus={(e) => {
                    this.setState({ schoolShow: true })
                  }} value={schoolName ? schoolName : value} />
                {schoolShow ?
                  <ul className="selectList">
                    {list.length > 0 ? list.map(x => {
                      return (
                        <li key={x.id} onClick={() => this.clickList(x)}>{x.name}</li>
                      )
                    }) : <div style={{ textAlign: 'center' }}>{language.no_data}</div>}
                  </ul> : ''}
              </div>
              <div className="identity" onClick={(e) => {
                e.stopPropagation()
              }}>
                <span>{language.identity_choice}：</span>
                <select name="" style={{ fontSize: 12, width: 75, outline: 'none' }} onClick={e => { this.setState({ identityValue: e.target.value }) }}>
                  <option value="0">{language.identity_teacher}</option>
                  <option value="1">{language.identity_student}</option>
                </select>
              </div>
            </div>
          }

          {/* <div className="Geetest" id="Geetest">
            <RGCaptcha
              name={CAPTCHA_NAME}
              width="100%"
              onSuccess={this.geetestValidate.bind(this)}
              data={this.geetest}
              lang={(window.localStorage.language && JSON.parse(window.localStorage.language) === 1) ? 'en' : 'zh-hk'}
            />
          </div> */}
          {
            login ?
              <div>
                <div className="loginUser">
                  <button onClick={this.login}>{language.login}</button>
                </div>
                <div className="guge">
                  <button>{language.google_login}</button>
                </div>
                <p>{language.no_account}<span onClick={this.loginShow}>{language.click_registered}</span></p>
              </div>
              :
              <div>
                <div className="loginUser">
                  <button onClick={this.registered}>{language.registered}</button>
                </div>
                <div className="guge">
                  <button onClick={this.loginShow}>{language.login}</button>
                </div>
              </div>
          }
        </div>
        <Popover
          // id={id}
          open={progressShow}
          anchorOrigin={{
            vertical: 200,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: '20px 30px', fontSize: 20 }}>
            <CircularProgress style={{ display: 'block', margin: '0 auto' }} variant="static" value={progress} />
          </div>
        </Popover>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(login);;
