import axios from 'axios'
import EventEmitter from 'events';

import * as ActionTypes from './type';
import _ from '../../utils'
import config from '../../config'

class Emitter extends EventEmitter {}

export const initChapterInfo = (titles, length) => ({
  type: ActionTypes.INITCHAPTERINFO,
  titles:titles,
  length: length
});

function count(max, callback) {
  const emitter = new Emitter();
  const success = (() => {
    let count = 0;
    return function () {
      if (++count < max) {
        return ;
      }
      callback.apply(this, arguments);
      count = 0
      console.log(emitter);
      emitter.removeListener('success', success);
    }
  })();
  emitter.on('success', success);

  return emitter;
}

const ajax = (method, headers) => {
  return (path, data, configuration={}) => axios({
    method,
    url: `${config.env.origin}${path}`,
    data,
    headers,
    ...configuration
  });
};

const post = ajax('post', { 'Authorization': 'yezicoco' });
const put = ajax('put', { 'Authorization': 'yezicoco' });

export const createCourseId = (callback) => (dispatch) => {
  axios({
    method: 'post',
    url: `${config.env.server}/curriculum/init`,
    data: {
      userId:'abcdefg',  // :TODO attach userId to user store
    },
    headers: {
      'Authorization': 'yezicoco'
    }
  })
  .then((response) => {
    dispatch(initCourseId(response.data));
    callback();
  })
  .catch((err)=>{
    console.log(err)
  })
};

export const initCourseId = data => ({
  type: ActionTypes.INITCOURSEID,
  courseId: data,
});

export const uploadChapterIntro = (chapterId,intro)=>(dispatch)=>{
  axios({
    method: 'post',
    url: `${config.env.server}/chapter/upload`,
    data: {
      chapterId,
      intro,  // :TODO attach userId to user store
    },
    headers: {
      'Authorization': 'yezicoco'
    }
  })
  .then(res=>{
    dispatch(updateChapterIntro(chapterId, intro));
  })
  .catch(err=>console.log(err))
};
export const uploadChapterCover = (chapterId, cover) => (dispatch) => {
  axios({
    method: 'put',
    url: `${config.env.server}/chapter/upload`,
    data: {
      chapterId,
      cover,  // :TODO attach userId to user store
    },
    headers: {
      'Authorization': 'yezicoco'
    }
  })
  .then(res=>{
    dispatch(updateChapterCover(chapterId, cover));
  })
  .catch(err=>console.log(err))
}
export const updateCourseInfo = (title, brief) => ({
  type:ActionTypes.UPDATECOURSEINFO,
  title,
  brief,
})

export const updateCourseCover = (cover) => ({
  type:ActionTypes.UPDATECOURSECOVER,
  cover,
})
export const updateCoursenumber = (number) => ({
  type:ActionTypes.UPDATECOURSEBUMBER,
  number,
})

export const updateCourseDemo = (demo) => ({
  type:ActionTypes.UPDATECOURSEDEMO,
  demo,
})

export const updateCourseAddCount = (count) => ({
  type: ActionTypes.UPDATECOURSEADDCOUNT,
  count,
})

export const updateChapterIntro = (chapterId,intro) => ({
  type:ActionTypes.UPDATECHAPTERINTRO,
  intro,
  chapterId
})

export const updateChapterCover = (chapterId, cover) => ({
  type:ActionTypes.UPDATECHAPTERCOVER,
  cover,
  chapterId
})
export const updateChapterSlides = (chapterId,pdf)=>({
  type:ActionTypes.UPDATECHAPTERSLIDES,
  chapterId,
  pdf
})

export const updateChapterList = (courseId, title, num) =>
  (dispatch, getState) => {
    const data = {
      courseId:courseId,
      chaptersId:[]
    };
    const chaptersList = {
      courseId,
      chapters:title,
    }
    const addChapterListToCurriculumCollection = ()=>{
      put('/curriculum/curriculum/upload',chaptersList)
        .then((res) => {
          console.log(res.data)
        })
        .catch(err => console.log(err))
    };
    const length = getState().upload.course.chapters;
    const extra = num - length;
    const updateInfo = () => {
      dispatch(updateChaptersInfo(courseId, title));
    };

    title.map((val,key)=>(data.chaptersId.push(`${courseId}_${key+1}`)))

    if (extra === 0) {
      updateInfo();

    } else if (extra > 0) {
      let newChaptersId = data.chaptersId.slice(-extra)
      data.chaptersId = newChaptersId;
      post('/curriculum/chapter/init', data)
        .then(res => {
          if (res.status === 200) {
            dispatch(updateChapters(num));
            updateInfo();
            addChapterListToCurriculumCollection();
          }
        })
        .catch(err => console.error(err));
    } else {

    }
  }
const updateChaptersInfo = (id, title) =>
  dispatch => {
    console.log(id, title);
    // const d2course = {
    //   courseId: id,
    //   chapters: title
    // }
    const d2chapter = {
      courseId: id,
    }
    const length = title.length;
    const emitter = count(length, () => console.log('all executed'));

    // put('/curriculum/upload', d2course)
    //   .then(res => {
    //     if (res.status === 200) {
    //       emitter.emit('success');
    //     }
    //   })
    //   .catch(err => console.error(err));

    for (let i = 0; i < length; i++) {
      const chapterId = `${id}_${i+1}`;
      const info = { title: title[i],clips:[],attachments:[],pdf:null,cover:null,intro:null};
      const data = {};
      Object.assign(data, d2chapter, { chapterId, ...info });
      put('/curriculum/chapter/upload', data)
        .then(res => {
          if (res.status === 200) {
            dispatch(updateChapter(chapterId, info));
            emitter.emit('success');
          }
        })
        .catch(err => console.error(err));
    }
    // this action has nothing to do with redux

  }

const updateChapters = length => ({
  type: ActionTypes.UPDATECHAPTERNUM,
  chapters: length
});

const updateChapter = (id, data) => ({
  type: ActionTypes.UPDATECHAPTER,
  id,
  data
});

export const uploadChapterClip = (file, courseId, chapterId) => (dispatch, getState) => {
  const data = new FormData();
  const clips = getState().upload[chapterId].clips.concat();
  const key = _.string(6, clips);
  data.append('courseId', courseId);
  data.append('chapterId', chapterId);
  data.append('uuid',`${chapterId}_${clips.length}`);
  data.append('clip', file);
  const data2Store = {
    filename: file.name,
    status:'Pending',
    percentage: 0,
  };
  dispatch(updateChapterClip(chapterId, key, data2Store));

  post('/utils/upload/clip', data, {
    onUploadProgress: e => {
      const { loaded, total } = e;
      const percent = parseInt(loaded/total*100)/100;
      dispatch(updateClipProgress(key, percent));
    }
  })
    .then(e => dispatch(updateClipStatus(key, 'Done')))
    .catch(err => console.log(err));
}

export const uploadChapterAttachment = (file, courseId, chapterId) => (dispatch, getState) => {
  const data = new FormData();
  const attachments = getState().upload[chapterId].attachments.concat();
  const key = _.string(6, attachments);
  data.append('courseId', courseId);
  data.append('chapterId', chapterId);
  data.append('uuid',`${chapterId}_${attachments.length}`);
  data.append('attachment', file);
  const data2Store = {
    filename: file.name,
    status:'Pending',
    percentage: 0,
  };
  dispatch(updateChapterAttachment(chapterId, key, data2Store));

  post('/utils/upload/attach', data, {
    onUploadProgress: e => {
      const { loaded, total } = e;
      const percent = parseInt(loaded/total*100)/100;
      console.log(percent);
      dispatch(updateAttachmentProgress(key, percent));
    }
  })
    .then(e => dispatch(updateAttachmentStatus(key, 'Done')))
    .catch(err => console.log(err));
}

const updateChapterAttachment = (id, key, attachment) => ({
  id,key, attachment,
  type:ActionTypes.UPDATECHAPTERATTACHMENTS,
})
const updateChapterClip = (id, key, clip) => ({
  id, key, clip,
  type: ActionTypes.UPDATECHAPTERCLIPS,
});

const updateClipProgress = (key, percent) => ({
  key, percent,
  type: ActionTypes.UPDATECLIPPROGRESS
});

const updateAttachmentProgress = (key, percent) => ({
  key, percent,
  type: ActionTypes.UPDATEATTACHMENTPROGRESS,
});

const updateClipStatus = (key, status) => ({
  key, status,
  type: ActionTypes.UPDATECLIPSTATUS
});
const updateAttachmentStatus = (key, status) => ({
  key, status,
  type: ActionTypes.UPDATEATTACHMENTSTATUS
});



export const nextStep = activeStep => ({
  type: ActionTypes.NEXTSTEP,
  activeStep: activeStep
});

export const lastStep = activeStep => ({
  type: ActionTypes.LASTSTEP,
  activeStep: activeStep
});
