import { create } from 'axios';
import * as ActionTypes from './type';
import config from '../../config';


const ajax2api = create({
  baseURL: `${config.env.origin}/api/api`,
  withCredentials: true,
});

const ajax2room = create({
  baseURL: `${config.env.server}/room`,
  headers: {
    'Authorization': 'yezicoco'
  },
  withCredentials: true,
});


export const signIn = data => ({
  type: ActionTypes.LOGIN,
  userData: data
});

export const signOut = () => dispatch => {
  ajax2api.get('/logout')
    .then(res => {
      console.log(res);
      if (res.status !== 200) return ;
      dispatch(clearUserData());
    })
    .catch(err => console.error(err));
}

const clearUserData = () => ({
  type: ActionTypes.LOGOUT,
});

export const getRoomList = () => dispatch => {
  ajax2room.get('/list')
    .then(res => {
      if (res.status !== 200) return ;
      const target = {};
      res.data.forEach(val => {
        const { roomId, title, brief, url, date } = val;
        if (title)
          target[roomId] = { roomId, title, brief, url, date };
      });
      dispatch(updateRoomList(target));
    })
    .catch(err => console.error(err));
};

const updateRoomList = rooms => ({
  type: ActionTypes.UPDATEROOMLIST,
  rooms
});
