import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

const Wrapper = styled.button`
  grid-area:${props=>props.areaname};
  color:${props=>props.color || '#fff'};
  background-color:${props=>props.backgroundColor || '#3D67BC'};
  padding:10px 20px;
  margin:${props=>props.margin || 0};
  border:0;
  border-radius:5px;
  display:block;
  font-size:24px;
  align-self:flex-end;
  transition:0.1s;
  cursor:pointer;
  :focus {
    outline:0
  }
  :active{
    box-shadow:none;
  }
  ${props => props.primary && css`
    background-color:#EA4D89;
    box-shadow:3px 3px 0px rgba(234, 77, 137, 0.50);
    `}
  ${props => props.secondary && css`
    background-color:#76B8DE;
    box-shadow:3px 3px 0px RGBA(59, 151, 251, 0.50);
    `}
`;

class Button extends React.Component {
  render () {
    return(
      <Wrapper
        className="Button"
        primary={this.props.primary}
        secondary={this.props.secondary}
        areaname={this.props.areaname}
        {...this.props}
        >
        {this.props.name}
      </Wrapper>
    )
  }
}

export default Button;
