import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getRoomList } from '../actions/user/index'
import RoomCard from '../components/RoomCard'
import Eraser from '../components/Eraser'
import curriculum from '../assets/img/Eraser__Curriculum.svg'


const mapStateToProps = state => {
  return {
    rooms: state.user.rooms || {}
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getRoomList() {
      dispatch(getRoomList());
    }
  }
}

const Container = styled.div`
  display: grid;
  grid-area: ${props=>props.areaname};
  grid-template-columns: 25% 75%;
  grid-template-areas:
  'sidebar main';
`;

const SideIndex = styled.div`
  grid-area: sidebar;
`;

const MainContent = styled.div`
  grid-area: main;
  margin: 25px;
`;

const RoomCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, calc((100% - 30px)/3));
  grid-gap: 15px 15px;
  @media screen and (max-width:1023px){
    grid-template-columns:1fr 1fr;
    grid-gap: 20px 20px;
  }
`

class UserInfo extends React.Component {
  componentDidMount() {
    this.props.getRoomList();
  }

  render () {
    const { rooms } = this.props;
    return (
      <Container areaname={this.props.areaname}>
        <SideIndex></SideIndex>
        <MainContent>
          <Eraser
            img={curriculum}
            name='我的房間'
          />
          <RoomCardContainer>
            {Object.values(rooms).reverse().map(v => (
              <RoomCard
                key={v.roomId}
                roomId={v.roomId}
                title={v.title}
                intro={v.brief}
                date={v.date}
                link={v.url}
              />
            ))}
          </RoomCardContainer>
        </MainContent>
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
