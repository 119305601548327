import React from 'react'
import styled from 'styled-components'
import move from '../assets/img/move.png'
import remove from '../assets/img/remove.png'
import { ReactComponent as Logo } from '../assets/img/Nav__Logo.svg';
//plugin axios
import axios from 'axios'
import config from '../config'

//redux
import { connect } from 'react-redux'


class Overviewitem extends React.Component {
  state = {
  };

  render() {
    return (
      <div
        css={`
          background-color:#fff
          margin:7px 5px;
          border: solid 1.3px grey;
          border-radius:5px;
        `}
      >
        <div css={`float:right;`}>
        <div
        css={`
          float:right;
          height:35px;
          width:35px;
          background-image: url(${remove});
        background-repeat: no-repeat;
        background-position:1px 2px;
        `}
      >2</div>

      <div
        css={`
          float: right;
          width: 35px;
          height:35px;
          background-color: cornflowerblue;
        `}
      >4</div>
        </div>
        <div>
        <div
          css={`
        float:left;
        width: 35px;
        height: 35px;
        // background-color:bisque
        border-right: grey solid 1px;
        border-radius:5px 0 0 5px;
        background-image: url(${move});
        background-repeat: no-repeat;
        background-position:1px 2px;
      `}

        >1</div>
        <div 
          css={`
      overflow:hidden;
      height:35px;
      margin-right: 70px;
      margin-left: 35px;
    `}
        >3</div>
      </div>
      </div>
    )
  }
}
export default Overviewitem;
