export const INITROOMID = 'initRoomId';
export const UPDATEROOM = 'updateRoom';
export const LASTSTEP = 'lastStep';
export const NEXTSTEP = 'nextStep';

export const UPDATEROOMINFO= 'updateRoomInfo'

export const UPDATEROOMSLIDES= 'updateRoomSlides'

export const UPDATEROOMCLIPPROGRESS = 'updateRoomClipProgress'
export const UPDATEROOMCLIPSTATUS = 'updateRoomClipStatus'
export const UPDATEROOMCLIP = 'updateRoomClip'

export const UPDATEROOMATTACHMENT = 'updateRoomAttachment'
export const UPDATEROOMATTACHMENTPROGRESS = 'updateRoomAttachmentProgress'
export const UPDATEROOMATTACHMENTSTATUS = 'updateRoomAttachmentStatus'

export const PENDINGQUEUEPUSH = 'pendingQueuePush';
export const PENDONGQUEUESHIFT = 'pendingQueueShift';

export const CANCELFILE = 'cancelFile';
