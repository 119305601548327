import * as ActionTypes from '../redux/ActionTypes';


export default (state=[], action) => {
	if (action.type === ActionTypes.SETFEATURES) {
		return [ ...state, ...action.features ]
	} else {
		return state;
	}
}
