import React from 'react'
import { ReactComponent as Delete} from '../assets/img/Cancel_Icon--withoutCircle.svg'
import remove from '../assets/img/remove.png'

class DelButton extends React.Component {
  state = {
    alpha: '0.5',
  }

  enter = e => {
    this.setState({ alpha: '1.0' });
  }

  leave = e => {
    this.setState({ alpha: '0.5' });
  }


  render () {
    const { alpha } = this.state;
    const { fn, width } = this.props;
    return (
			<div
        css={`
          background-image: url(${remove});
					cursor: pointer;
          opacity: ${alpha};
          width: ${width};
          width: 40px;
          height: 50px;
          background-repeat: no-repeat;
          background-position: -1px 5px;
				`}
				onMouseEnter={this.enter}
        onMouseLeave={this.leave}
        onClick={fn}
			/>
    )
  }
}

export default DelButton;
