import * as ActionTypes from '../redux/ActionTypes';


export default (state=[], action) => {
  if (action.type === ActionTypes.SETCOURSE) {
    //const { demo, uploader, ...course } = action.courseInfo;
    return { ...state, ...action.courseInfo }; //...state, ...action.app
  } else {
    return state;
  }
}