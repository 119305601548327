import React from 'react'
import styled from 'styled-components'
//components
import Subtitle from './Subtitle'


const Wrapper = styled.ul`
  width:100%;
  grid-area:${props => props.areaname};
  font-size:1em;
  margin:0;
  padding:0;
  color:${props => props.color || '#27303B'};
  background-color:#76B8DE;
  display:grid;
  grid-gap:10px;
  padding:10px;
  border-radius:5px;
  box-sizing:border-box;
`;

const Bullet = styled.li`
  width:100%;
  height:auto;
  display:inline-block;
  display:grid;
  grid-template-columns: 20% 80%;
  background-color:#fff;
  box-sizing:border-box;
  border-radius:5px;
  overflow:hidden;
`;

class Chapter extends React.Component {

  render() {
    const _arr = this.props.chapters ? (this.props.chapters instanceof Array?this.props.chapters:this.props.chapters.data) : ([]);
    return (
      <Wrapper areaname={this.props.areaname}>
        {_arr.map((val, index) => (
          <Bullet key={`Chapter__Bullet--${index}`}>
            <div style={{color:'#27303B',backgroundColor:'#E1EDF7',height:'100%',width:'100%'}}>
              <Subtitle
                name={index + 1}
                css={`
                text-align:center;
                // padding:14px 0;
                font-size:1.5em;
                box-sizing:border-box;
                place-self:center;
                position:relative;
                top:calc(50% - 12px)
              `}
              />
            </div>
            <Subtitle
              name={val.title} //{this.props.chapters[val]}
              color='#27303B'
              css={`
                padding:14px 0;
                font-size:1.5em;
                box-sizing:border-box;
              `}
            />
          </Bullet>
        ))}
      </Wrapper>
    )
  }
}

export default Chapter;
