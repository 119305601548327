import * as ActionTypes from '../redux/ActionTypes';


export default (state=[], action) => {
  if (action.type === ActionTypes.SETCHAPTER) {
    const { chapterId, chapterData } = action;
    return { ...state, [chapterId]: chapterData };
  } else {
    return state;
  }
}