import React from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// connect
import { connect } from 'react-redux';
//actions
import { nextStep, lastStep } from '../actions/upload';

const Wrapper = styled.div`
  width: 100%;
  text-align:left;
  grid-area: steppers;
  margin-left: -24px;
`;

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    // padding: theme.spacing.unit * 3,
  },
});

const mapStateToProps = (state) => ({
  activeStep: state.steppers.activeStep
})

const mapDispatchToProps = (dispatch) => ({
  handleNext(activeStep) {
    dispatch(nextStep(activeStep))
  },
  handleBack(activeStep) {
    dispatch(lastStep(activeStep))
  }
})

class Steppers extends React.Component {
  render() {
    const { classes, steps, stepsContent } = this.props;
    const { activeStep } = this.props;
    return (
      <Wrapper>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
        >
          {steps.map((label, index) => (
            <Step key={label} > 
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography >{stepsContent[index]}</Typography>
                <div className={classes.actionsContainer}>
                  <div>
                    {/*<Button
                     disabled={activeStep === 0}
                     onClick={()=>{this.props.handleBack(activeStep)}}
                     className={classes.button}
                   >
                     返回
                   </Button>
                   <Button
                     variant="contained"
                     color="primary"
                     onClick={()=>{this.props.handleNext(activeStep)}}
                     className={classes.button}
                   >
                     {activeStep === steps.length - 1 ? '完成' : '下一步'}
                   </Button>*/}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Wrapper>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Steppers));
