import React from 'react'
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link'
import { Close, Settings, Launch } from '@material-ui/icons';
import axios from 'axios';


const styles = {
  card: {
    maxWidth: 380,
  },
  actions: {
    display: 'flex',
  },
  content: {
    padding: 8
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  settings: {
    marginLeft: 'auto',
  },
  link: {
    display: 'flex',
  }
};

const Wrapper = styled.div`
  grid-area: ${props=>props.areaname};
  height: 100%;
`;


class RoomCard extends React.Component {
  render () {
    const { classes, roomId, title, intro, date, link } = this.props;
    const created = date? new Date(date).toLocaleDateString(): '';
    return (
      <Wrapper>
        <Card className={classes.card}>
          <CardHeader
            title={
              <Typography className={classes.text} align="center" gutterBottom variant="subtitle1" >
                {title}
              </Typography>
            }
            subheader={created}
            action={
              <IconButton
                onClick={() => {
                    axios.delete(`//staging.cocorobo.hk/curriculum/room/id/${roomId}`, {
                      withCredentials: true,
                      headers: {
                        'Authorization': 'yezicoco'
                      },
                    })
                      .then(res => console.log(res))
                  }
                }
              >
                <Close />
              </IconButton>
            }
          />
          <CardContent className={classes.content}>
            <Typography align="left" gutterBottom variant="body1" >
              房間號碼： {roomId}
            </Typography>
            <Typography className={classes.text} align="left" variant="body1" >
              簡介： {intro}
            </Typography>
          </CardContent>
          <CardActions className={classes.actions} disableActionSpacing >
            <IconButton
              className={classes.settings}
            >
              <Settings />
            </IconButton>
            <IconButton>
              <Link
                className={classes.link}
                href={link}
                color="inherit"
                target="_blank"
                underline="none"
              >
                <Launch />
              </Link>
            </IconButton>
          </CardActions>
        </Card>
      </Wrapper>
    )
  }
}

export default withStyles(styles)(RoomCard);
