import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
const Wrapper = styled.div`
    grid-area:overlook;
    border-radius: 10px;
    // background-color:  #3D67BC;
    display: inline-table;
    position:fixed;
    right:30px;
`;
const Block = styled.div`
    // overflow:hidden;
    // margin: 10px;
    background: #fff;
    // border-radius: 10px;
    min-width: 100px;
    height:40px;
    padding: 10px 10px 10px 10px;
    text-align: justify;
    // float:left;
`;
const Font = styled.div`
    text-decoration: none; 
    text-overflow: ellipsis; 
    overflow: hidden;
    height:22px;
    // color:black;
    font-size:14px;
    font-weight:bold;
    margin: 0px 0 0 5px;
`;


const Fonts = styled.div`
  font-size:12px;
  grid-area: font;
  text-align: left;
  padding:10px;
  color:#000;
`;

const mapStateToProps = (state) => {
    return {
        initchapters: state.newupload.chapter.initchapters
    }
}



class Chapteroverlook extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initchapters: JSON.parse(window.localStorage.arr).map((x, k) => {
                if (k === 0) {
                    x.state = true
                } else {
                    x.state = false
                }
                return x
            })
        }
    }
    componentDidMount() {
        var _this = this;
        if (this.props.handleScroll) {
            this.props.handleScroll.chapterscroll = function (e) {
                var i = Math.floor((e.currentTarget.scrollTop - 74) / 1134);
                i = i <= 0 ? 0 : i;
                i = i >= _this.state.initchapters.length -1 ? _this.state.initchapters.length - 1 : i;
                _this.clickTile(i);
            }
        }
    }

    clickTile = (num) => {
        let arr = this.state.initchapters.map((x, k) => {
            const obj = x
            if (num === k) {
                obj.state = true;
            } else {
                obj.state = false;
            }
            return obj
        })
        this.setState({
            initchapters: arr
        })
    }

    render() {
        // const { initchapters } = this.props;
        const { initchapters } = this.state
        var ItemList = () => {
            let list = [];
            for (let i = 0; i < initchapters.length; i++) {
                list.push(
                    <React.Fragment key={i}>
                        <Block>
                            <a onClick={() => this.clickTile(i)} css={initchapters[i].state ? 'text-decoration:none;color:#000' : `text-decoration:none;color:#ccc`} href={`#` + initchapters[i].title}>
                                <Font>{`第${i + 1}章: ${initchapters[i].title.length > 15 ? initchapters[i].title.slice(0, 12) + '...' : initchapters[i].title}`}</Font>
                            </a>
                        </Block>
                    </React.Fragment>
                )
            }
            return list;
        }
        return (
            <div>
                <Wrapper>
                    {/* <Fonts>點擊下欄中任意一個章節,對該章節的資料進行完善。</Fonts> */}
                    <div style={{ display: 'felx' }}>
                        {ItemList()}
                    </div>
                </Wrapper>
            </div>
        )
    }
}
export default connect(mapStateToProps, null)(Chapteroverlook);