import React from 'react'
import { connect } from 'react-redux'
import '../css/profile.css'
import axios from 'axios';
import config from '../config'
//  import youjian from '../../public/img/youjian.jpg'
import { toggleUserDropdown } from '../redux/Actions';
import Modal from '@material-ui/core/Modal';

const mapDispatchToProps = dispatch => ({
  openUserDropdown(state) {
    dispatch(toggleUserDropdown(state));
  },
});
const mapStateToProps = state => ({
  banners: state.banners,
  app: state.app
});
// cookie
const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
class profile extends React.Component {

  state = {
    userId: '',
    loading: true,
    permissions: false
  }

  componentDidMount() {
    window.ServerIp = config.env.edu;
    let _this = this
    let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
    if (!arr) {
      this.props.history.push('/login')
    }
    else {
      axios.defaults.withCredentials = true;
      axios.get(`${config.env.server}getcookieuserid`).then(res => {
        if (res.data[0][0]) {
          window.UserId = res.data[0][0].userid;
          window.language = _this.props.language
          let show = false
          if (res.data[0][0].identity === 2 || res.data[0][0].identity === 0) {
            show = true;
          }
          this.setState({
            userId: res.data[0][0].userid,
            permissions: show
          });
          const script = document.createElement('script');
          script.type = "text/javascript";
          script.src = "/profileF.js";
          script.async = "async";
          script.onload = function () {
            window.eduonload();
            _this.setState({
              loading: false
            })
          }
          document.body.appendChild(script);
        }
      }).catch(err => {
        this.props.history.push('/login');
        // window.alert(err.response.data)
      })
    }
  }

  componentWillUnmount = () => {
    window.sessionStorage.setItem('navClick', 0)
  }

  render() {
    const { permissions } = this.state
    const { language } = this.props
    return (
      <div className="p_page">
        <div className="p_nav">
          <div class="divhover"><img src="/img/profile-2.png" alt="" />{language.personal_center}</div>
          <div><img src="/img/notice-1.png" alt="" />{language.notification_center}</div>
          <div><img src="/img/text-1.png" alt="" />{language.data_management}</div>
          <div style={permissions ? { display: "block" } : { display: "none" }}><img src="/img/class-1.png" alt="" />{language.course_managment}</div>
          <div style={permissions ? { display: "block" } : { display: "none" }}><img src="/img/home-1.png" alt="" />{language.room_managment}</div>
          {/* <div style={permissions ? { display: "block" } : { display: "none" }}><img src="/img/home-1.png" alt="" />{language.school_managment}</div> */}
          {/* <div><img src="/img/home-1.png" alt=""/>{language.friend_group}</div> */}
        </div>
        <div className="p_bjshow">
          <div className="p_table">
            {/**個人中心 */}
            <div className="p_table_block">
              <div class="hp_main">
                <div class="hp_main_box" style={permissions ? { display: "block" } : { display: "none" }}>
                  <div class="pb_head">我的上傳<sapn class="hp_My">&nbsp;&nbsp;My</sapn></div>
                  <div class="hp_big">
                  </div>
                  <div class="scpage"></div>
                </div>
                <div class="hp_main_box">
                  <div class="pb_head">我的訂閱<sapn class="hp_My">&nbsp;&nbsp;Subscribe</sapn></div>
                  <div class="hp_big ">
                  </div>
                  <div class="dypage"></div>
                </div>
                <div class="hp_main_box">
                  <div class="pb_head">我的收藏<sapn class="hp_My">&nbsp;&nbsp;Collect</sapn></div>
                  <div class="hp_big ">
                  </div>
                  <div class="shoucpage"></div>
                </div>
              </div>
            </div>
            {/**通知中心 */}
            <div className="p_table_div">
              <div>
                <div class="pb_head">通知中心<span class="hp_My">&nbsp;&nbsp;Notifications</span></div>
                <div class="content_">
                </div>
                <div class="newscontent_page"></div>
                {/* <button class="content_button">返回首頁</button> */}
              </div>
            </div>
            {/*資料管理*/}
            <div className="p_table_div">
              <div style={{ marginLeft: '35px' }}>
                <div class="pb_head" style={{ marginLeft: '0px' }}>
                  <span>我的資料<span class="hp_My">&nbsp;&nbsp;Profile</span></span>
                </div>
                <div class="my_all">
                  <div class="my_name">
                    <div>
                      <span>{language.name}：</span>
                      <input type="text" class="my_text" id="my_name" placeholder={language.input_name} />
                    </div>
                  </div>
                  <div class="my_name">
                    <div style={{ width: "100%" }}>
                      <span>{language.personal_profile}：</span>
                      <textarea style={{ fontWeight: 600, font: '400 18px Arial' }} class="my_area" id='my_grjj' placeholder={language.personal_profile_input} />
                    </div>
                  </div>
                  <div class="my_name" >
                    <div class="my_sf">
                      <span>{language.school}：</span>
                      <select type="text" class="my_text" id='my_school'>
                      </select>
                    </div>
                    <div class="my_sf">
                      <span>{language.phone}：</span>
                      <input type="text" placeholder={language.input_phone} id='my_tel' class="my_text" />
                    </div>
                  </div>
                  <div class="my_name">
                    <div class="my_sf" id="jianju">
                      <span>{language.sex}：</span>
                      <input type="radio" name="fix" class='my_sex' /><i style={{ margin: '0 3px 0 0' }}></i>{language.men}<i style={{ margin: ' 0 15px 0 0' }}></i>
                      <input type="radio" name="fix" class='my_sex' /><i style={{ margin: '0 3px 0 0' }}></i>{language.women}
                    </div>
                    <div class="my_sf">
                      <span>{language.electronic_email}：</span>
                      <input type="text" placeholder={language.electronic_email} id='my_email' class="my_text" />
                      {/* <span id='my_email' ></span> */}
                    </div>

                  </div>
                  <div class="my_name" style={{ margin: '30px 0 0 0' }}>
                    <div class="anniu">
                      <input type="button" value={language.edit} id="my_anniu" />
                    </div>
                  </div>
                  <div class="my_name" style={{ display: 'none' }}>
                    <div class="my_sf">
                      <span>身份：</span>
                      <select name="" id="sf">
                        <option value="0">教師</option>
                        <option value="1">學生</option>
                        <option value="2">管理員</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 課程管理 */}
            <div class="p_table_div p_course">
              <div class="pb_head">
                課程管理<span class="hp_My">&nbsp;&nbsp;Curriculum</span>
                <p>n個課程</p>
              </div>
              {/*<div class='p_course_input' style={{ margin: '30px 30px 0 0px', height: '36px' }}>
                <div class='p_course_search'></div>
                <input class="p_course_searchI" placeholder={language.course_name} />
                style={{ float: 'right', width: '200px', marginTop: '5px', height: '30px', fontSize: '18px', textIndent: '10px' }}
              </div>*/}
              <div class='p_course_all'>
              </div>
              <div class='p_course_modify' style={{ display: 'none' }}>
                <div>
                  <div style={{ fontSize: '28px', float: 'left', margin: '20px 20px 0px 30px' }}>課程名稱:</div>
                  <input class="p_course_modify_name" style={{ width: '885px', marginTop: '25px', marginBottom: '25px', height: '30px' }} />
                </div>
                <div>
                  <div style={{ fontSize: '28px', margin: '20px 30px' }}>課程簡介:</div>
                  <textarea class="p_course_modify_jianjie" style={{ marginLeft: '30px', width: '1025px', height: '150px' }} />
                </div>
                <div>
                  <div style={{ fontSize: '28px', margin: '20px 30px' }}>課程封面:</div>
                  <img class="p_course_modify_img1" style={{ border: '1px solid', float: 'left', width: '470px', height: '300px', marginLeft: '30px' }} />
                  <img class="p_course_modify_img2" style={{ border: '1px solid', width: '470px', height: '300px', marginLeft: '80px' }} />
                </div>
                <div>
                  <div style={{ fontSize: '28px', margin: '20px 20px 0px 30px' }}>課程章節:</div>''
                <div style={{ border: '1px solid', width: '1020px', float: 'left', 'height': '300px', marginLeft: '30px' }}>
                    <div class='p_course_chapters_modify' style={{ float: 'left', border: '1px solid', width: '200px', height: '50px', margin: '10px', borderRadius: '5px', lineHeight: '50px', textAlign: 'center' }}>第一章:第一第一第一</div>
                    <div class='p_course_chapters_add' style={{ float: 'left', border: '1px dashed', width: '200px', height: '50px', margin: '10px', borderRadius: '5px', lineHeight: '50px', textAlign: 'center' }}>新增章節</div>
                  </div>
                </div>
                <div>
                  <div style={{ float: 'left', border: '1px solid', padding: '5px 10px', borderRadius: '5px', margin: '20px 0px 0px 500px' }}>取消修改</div>
                  <div style={{ float: 'left', border: '1px solid', padding: '5px 10px', borderRadius: '5px', margin: '20px 0px 0px 50px', background: '#76b8de', color: '#fff' }}>保存設置</div>
                </div>
              </div>
              <div class="form" style={{ display: 'none' }}>
                {/* <div class="form-window-cover"></div> */}
                <div class="form-window">
                  <div class="form-head"><span class="form-logo"></span>
                    <h3>修改章節</h3><span class="f_cancel">×</span>
                  </div>
                  <div class="form-main">
                    <div class="form-content">
                      <div class="form_delete-main">
                        <div>章節名</div>
                        <input style={{ margin: '2px 0px 0px 10px', width: '320px' }} />
                      </div>
                    </div>
                  </div>
                  <div class="form-b​​tn"><button class="form-confirm">確認</button><button class="form-cencal">取消</button></div>
                </div>
              </div>
            </div>
            {/**房間管理 */}
            <div className="p_table_div">
              <div class="pb_head">
                房間管理<span class="hp_My">&nbsp;&nbsp;Room</span>
                <p>8個房間</p>
              </div>
              <div>
                <div>
                  <div class="room_select">
                    <div class="room_insert" id="room_insert">{language.add_room}</div>
                    <div class="room_select_right">
                      <input type="text" class="room_select_right_input" placeholder={language.room_name} />
                      <button class="room_select_right_button"></button>
                    </div>
                  </div>
                  <div class="room_class">
                  </div>
                  <div class="show_room_page"></div>
                </div>
              </div>
            </div>
            {/**好友分組 */}
            <div className="p_table_div">
              <div class="pb_head">
                好友分組<span class="hp_My">Friend</span>
                <div class="friend_search">
                  <input type="text" class="friend_searchbox" placeholder="查询"></input>
                  <button class="friend_searchbtn"></button>
                </div>
                <div class="friend_allbtn">
                  <span class="friend_Btn" >刪除好友</span>
                  <span class="friend_Btn" >添加好友</span>
                  <span class="friend_Btn" >新建分組</span>
                </div>
              </div>
              <div class="friend_groups">
                {/* <div class="friend_addgroups">
                      <span>全部分組</span>
                      <div class="friend_allgroups">110</div>
                      <div class="friend_groupsdelete"></div>
                  </div>
                  <div class="friend_groudsbody">
                      <div class="friend_groupslist">
                          <span>朋友</span>
                          <div class="friend_photodelete"></div>
                          <div class="friend_groupspeople">5</div>
                      </div>
                      <div class="friend_groupslist">
                          <span>朋友</span>
                          <div class="friend_photodelete"></div>
                          <div class="friend_groupspeople">5</div>
                      </div> 
                      <div class="friend_groupslist">
                          <span>朋友</span>
                          <div class="friend_photodelete"></div>
                          <div class="friend_groupspeople">5</div>
                      </div>
                      <div class="friend_groupslist">
                          <span>朋友</span>
                          <div class="friend_photodelete"></div>
                          <div class="friend_groupspeople">5</div>
                      </div>
                  </div> */}
              </div>
              <div class="friend_people listing">
                <ul class="friend_head listing_head">
                  <li>
                    <div class="friend_first">
                      <input type="checkbox" class="chose-student"></input>
                    </div>
                    <div class="friend_second">學生</div>
                    <div class="friend_third">學校</div>
                    <div class="friend_first">分組</div>
                    <div class="friend_third">郵箱號</div>
                    <div class="friend_second">操作</div>
                  </li>
                </ul>
                <ul class="friend_tbody notice_tbody">
                  {/* <li>
                     <div class="friend_first">
                       <input type="checkbox" class="chose-student"></input>
                     </div>
                     <div class="friend_second">七七</div>
                     <div class="friend_third">深圳技师学院</div>
                     <div class="friend_first">學生</div>
                     <div class="friend_third">956798445@qq.com</div>
                     <div class="friend_second"><span class="friend_deleteBtn" id="3403a903-facb-11e9-96f9-028edca3b798">刪除</span></div>
                     </li>
                     <li>
                     <div class="friend_first">
                       <input type="checkbox" class="chose-student"></input>
                     </div>
                     <div class="friend_second">七七</div>
                     <div class="friend_third">深圳技师学院</div>
                     <div class="friend_first">學生</div>
                     <div class="friend_third">956798445@qq.com</div>
                     <div class="friend_second"><span class="friend_deleteBtn" id="3403a903-facb-11e9-96f9-028edca3b798">刪除</span></div>
                     </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.loading}
          onClose={() => {
            this.setState({
              loading: true
            })
          }}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <img src={require('../assets/img/loading1.gif')} style={{ width: 500, height: 300, position: 'absolute', top: 'calc(50% - 150px)', left: 'calc(50% - 250px)' }} />
            <p style={{ position: 'absolute', width: '100%', top: 'calc(50% + 75px)', textAlign: 'center', color: '#fff' }}>{language.loadings}</p>
          </div>
        </Modal>
      </div>
    )
  }
}

export default connect(mapDispatchToProps, mapStateToProps)(profile);

