import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import '../css/preview.css';
import axios from 'axios';
import qs from 'qs';
import config from '../config'
import { toggleRoom, toggleLogin, toggleUserDropdown } from '../redux/Actions';

const regEmail = new RegExp("[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+$");
const regpass = new RegExp(/^(?=.*\d)(?=.*[A-Za-z]).{6,}$/);
const mapDispatchToProps = (dispatch) => ({
    openRoomModal(state) {
        dispatch(toggleRoom(state));
    },
    toggleLogin(state) {
        dispatch(toggleLogin(state));
    },
    openUserDropdown(state) {
        dispatch(toggleUserDropdown(state));
    },
});
const mapStateToProps = state => {
    const { profile } = state.user;
    return {
        loginModalIsOpen: state.modals.loginModalIsOpen,
        userDropdownIsOpen: state.modals.userDropdownIsOpen,
        account: profile && profile.account,
        name: profile && profile.name
    };
};
class MobileView extends React.Component {
    // const [completed, setCompleted] = useState(0)
    constructor(props) {
        super(props)
        this.state = {
            isShow: true,
            email: '',   // 郵箱
            passWord: '',    // 密碼
            clickShow: true, // 點擊後自毀
        }
    }

    componentDidMount() {
        document.getElementsByClassName('mobile_View')[0].parentNode.children[0].style.display = 'none'
        document.getElementsByClassName('mobile_View')[0].parentNode.children[2].style.display = 'none'
        if (this.QueryString('email') && this.QueryString('key')) {
            this.setState({
                isShow: false
            })
        }
    }

    // 截取某個字符串
    QueryString = (item) => {
        var sValue = window.location.search.match(new RegExp("[\?\&]" + item + "=([^\&]*)(\&?)", "i"));
        return sValue ? sValue[1] : sValue;
    }

    submit = () => {

    }

    render() {
        return (
            <div className="mobile_View" >
                {/* <!-- 頭部 --> */}
                <div className="yl-head">
                    <Link to="/mobileHome">
                        <span><img src={require('../assets/images/return.png')} alt="" /></span>
                    </Link>
                    <span>在線預覽</span>
                </div>
                {/* <!-- 中間內容 --> */}
                <div className="yl-content">
                    <div>
                        <div className="yl-left">
                            <img src={require('../assets/images/touxiang.png')} alt="" className="yl-cimg" />
                        </div>
                        <div className="yl-right">
                            <span className="yl-rspan">林追追LING</span>
                            <span className="yl-clock">2019-08-05 18:01:34</span>
                        </div>
                    </div>
                    <img src={require('../assets/images/ship.png')} alt="" className="yl-img" />
                </div>
                {/* <!-- 留言區 --> */}
                <div className="ly-bar">
                    <div className="ly-wenzi">
                        <span className="ly-span1">未命名作品</span><br />
                        <span className="ly-span2">作者還沒有填寫作品說明喲</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileView);;
